import React, { useEffect } from "react";
// import "./Content.scss";
import { Route, Routes, useLocation } from "react-router-dom";
// import Navbar from "../components/Navbar";
import Home from "./Home";
import About from "./About";
import Industries from "./Industries";
import Models from "./Models";
import Services from "./Services";
import Contact from "./Contact";
import HealthTechnology from "./industries/HealthTechnology";
import Portfolio from "./Portfolio";
// import { useLayoutEffect } from "react";
import EcommerceInd from "./industries/Ecommerce";
import EducationInd from "./industries/Education";
import Fintech from "./industries/Fintech";
import Food from "./industries/Food";
import Realestate from "./industries/Realestate";
import CommunicationMedia from "./industries/CommunicationMedia";
import TransportLogistics from "./industries/TransportLogistics";
import Backtop from "../components/Backtop";

const routes = [
    { path: "/", name: "Home", Component: Home },
    { path: "/services", name: "Services", Component: Services },
    { path: "/industries", name: "Industries", Component: Industries },
    { path: "/models", name: "Models", Component: Models },
    { path: "/portfolio", name: "Portfolio", Component: Portfolio },
    { path: "/about", name: "About", Component: About },
    { path: "/contact", name: "Contact", Component: Contact },
    { path: "/industries/health-tech", name: "health-tech", Component: HealthTechnology },
    { path: "/industries/ecommerce", name: "ecommerce", Component: EcommerceInd },
    { path: "/industries/education", name: "education", Component: EducationInd },
    { path: "/industries/food", name: "food", Component: Food },
    { path: "/industries/fintech", name: "Fintech", Component: Fintech },
    { path: "/industries/realestate", name: "Real Estate", Component: Realestate },
    { path: "/industries/communication-media-entertainment", name: "Communication, Media & Entertaiment", Component: CommunicationMedia },
    { path: "/industries/trasnport-logistics", name: "Transport & Logistics", Component: TransportLogistics },
];

const routeComponents = routes.map(({ path, Component }) => <Route path={path} element={<Component />} />);

function Layout() {
    const location = useLocation();
    const pathName = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, [pathName]);

    return (
        <>
            <Routes key={location.pathname} location={location}>
                {routeComponents}
            </Routes>
            <Backtop />
        </>
    );
}

export default Layout;
