import TransportManagement from "../images/industries/transport/trasnport_mangement.png";
import SupplyChain from "../images/industries/transport/supply_chain.png";
import FleetManagement from "../images/industries/transport/fleet_management.png";
import WarehouseManage from "../images/industries/transport/warehouse.png";
import COurierDelivery from "../images/industries/transport/courier_delivery.png";

export const TransportData = [
    {
        id: 1,
        solutionIcon: TransportManagement,
        solutionTitle: "Transport Management Solutions",
        solutionDesc:
            "Our Transportation Management Solution is crafted to streamline your logistics operations, optimise routes, and enhance overall supply chain efficiency. Our features include Real-Time Tracking, Route Optimization, Process received orders, Billing & Invoicing Tools, Load Planning, and Cost Management.",
    },
    {
        id: 2,
        solutionIcon: SupplyChain,
        solutionTitle: "Supply Chain Management",
        solutionDesc:
            "At Wing Coders, we recognize the critical role of a well-managed supply chain in the success of your business. Our Supply Chain Management Software is meticulously crafted to streamline your logistics, enhance visibility, and optimise overall supply chain efficiency.",
    },

    {
        id: 3,
        solutionIcon: FleetManagement,
        solutionTitle: "Fleet Management",
        solutionDesc:
            "Leverage our logistics software which effectively manages your fleet with our comprehensive fleet management tools. Monitor vehicle health, track maintenance schedules, and enhance overall performance.",
    },
    {
        id: 4,
        solutionIcon: WarehouseManage,
        solutionTitle: "Warehouse Management",
        solutionDesc:
            "We recognize the critical synergy between warehouse management and transportation logistics in the transportation industry. Our Warehouse Management Software is meticulously crafted to seamlessly integrate with transportation operations, enhancing efficiency and optimising the entire supply chain.",
    },
    {
        id: 5,
        solutionIcon: COurierDelivery,
        solutionTitle: "Courier Delivery",
        solutionDesc:
            "Our Courier Delivery Software is purpose-built to streamline operations, enhance efficiency, and provide real-time visibility in the fast-paced world of courier logistics. Our key features include Real-time Tracking, Dynamic Route Optimization, Automated Dispatch, Customer Communication, and Proof of Delivery.",
    },
];
