import React from "react";
import { MdOutlineFormatQuote } from "react-icons/md";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import HomeReviewImg from "../images/home_review_image.png";
import "swiper/css";
const HomeReviews = () => {
    return (
        <section className="home_testimonial_section">
            <div className="container ">
                <div className="home_testimonial_section_wrapper">
                    <h6 className="subtitle subtitle--primary">Testimonials</h6>
                    <h2 className="display-3 mb-5">
                        Our Client's <span className="text-primary">Feedback</span>
                    </h2>

                    <div className="home_testimonial_wrapper">
                        <div className="home_testimonial">
                            <div className="home_testimonial_bg">
                                <MdOutlineFormatQuote />
                            </div>
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                // loop={true}
                                grabCursor={true}
                                centeredSlides={true}
                                spaceBetween={100}
                                autoHeight={true}
                                slidesPerView="1"
                                speed={1500}
                                autoplay={{ delay: 5000 }}
                                navigation={{
                                    nextEl: ".testimonial_navigation .next-button",
                                    prevEl: ".testimonial_navigation .prev-button",
                                }}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                        spaceBetween: 40,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 50,
                                    },

                                    1100: {
                                        slidesPerView: 1,
                                        spaceBetween: 100,
                                    },
                                }}
                                // scrollbar={{ draggable: true }}
                                className="testimonial_slider"
                            >
                                {homeReviewsContent.map((item, index) => (
                                    <SwiperSlide className="testimonial_slider_item" key={index}>
                                        <div className="testimonial_slider_item_wrapper">
                                            <p className="paragraph">
                                                {" "}
                                                <span className="testimonial_slider_item_quote_icon">
                                                    <FaQuoteLeft />
                                                </span>
                                                {item.reviewContent}
                                                <span className="testimonial_slider_item_quote_icon">
                                                    <FaQuoteRight />
                                                </span>{" "}
                                            </p>

                                            <div className="testimonial_slider_item_name">
                                                <h5>{item.reviewName}</h5>
                                                <p>{item.reviewProfile}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="testimonial_navigation" id="testimonial_navigation">
                                <button className="prev-button">
                                    <AiOutlineArrowLeft />
                                </button>
                                <button className="next-button">
                                    <AiOutlineArrowRight />
                                </button>
                            </div>
                        </div>
                        {/* <div className="home_testimonial_content">
                    <div className="home_testimonial_content_wrapper">
                        <img src={HomeReviewImg} alt="Wingcoders testimonials" title="wingcoders testimonials" />
                    </div>
                </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeReviews;

const homeReviewsContent = [
    {
        id: 1,
        reviewContent:
            "I must admit that I am quite happy with my website now. A big kudos to the Wing Coders crew for helping me out every time I tried to reach them. I know I can count on you guys even in the future.",
        reviewName: "Pradeep",
        reviewProfile: "Bahrain",
    },
    {
        id: 2,
        reviewContent:
            "Wing Coders owing eminent developers and they all were exceedingly technically skilled experts. They are so diligent and responsive in solving any technical issues and have worked really hard to finish both iOS and Android apps. This has been a very challenging project but the team has stepped up and gotten the work done to high quality",
        reviewName: "Naveen",
        reviewProfile: "India",
    },
    {
        id: 3,
        reviewContent:
            "Wing Coders doesn’t talk about what’s good for Wing Coders. They say, “Here’s what we’re seeing and here’s what we want to do to help your business.” That is something everyone can’t buy.",
        reviewName: "Dhanapal",
        reviewProfile: "Canada",
    },
];
