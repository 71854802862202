import React, { useEffect, useRef } from "react";
import { gsap } from "gsap/dist/gsap";
import { MdOutlineMedicalServices } from "react-icons/md";
import { Ecommerce, Education, FoodTech, HealthTech } from "../icons/HomeIcons";
import { FintechIcon, RealestateIcon, CommunicationIcon, TransportIcon } from "../icons/IndustriesIcons";
import { Link } from "react-router-dom";
const HomeIndustries = () => {
    const homeParallaxRef = useRef(null);

    useEffect(() => {
        if (homeParallaxRef.current == null) return;
        const tween = gsap.to(homeParallaxRef.current, {
            backgroundPosition: `50% ${-window.innerHeight / 2}px`,
            ease: "none",
            scrollTrigger: {
                trigger: homeParallaxRef.current,
                scrub: true,
            },
        });

        return () => {
            tween.kill();
        };
    }, []);

    return (
        <section className="parallax_big">
            <div ref={homeParallaxRef} className="parallax_image_big"></div>
            <div className="home_industry">
                <div className="container">
                    <h2 className="display-3 text-center mx-auto text-white mb-5">
                        Industries <span className="text-primary">We Serve</span>
                    </h2>
                    <div className="home_industry_items">
                        {homeIndustryContent.map((item, index) => (
                            <div className="home_industry_item" key={index}>
                                <Link to={item.path}>
                                    <div className="home_industry_item_icon">{item.industryIcon}</div>
                                    <div className="home_industry_item_title">{item.industryTitle}</div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeIndustries;

const homeIndustryContent = [
    {
        id: 1,
        industryIcon: <HealthTech />,
        industryTitle: "Health Tech",
        path: "/industries/health-tech",
    },
    {
        id: 2,
        industryIcon: <Ecommerce />,
        industryTitle: "Ecommerce ",
        path: "/industries/ecommerce",
    },
    {
        id: 3,
        industryIcon: <Education />,
        industryTitle: "Education",
        path: "/industries/education",
    },
    {
        id: 4,
        industryIcon: <FoodTech />,
        industryTitle: "Food",
        path: "/industries/food",
    },
    {
        id: 5,
        industryIcon: <FintechIcon />,
        industryTitle: "Fintech",
        path: "/industries/fintech",
    },
    {
        id: 6,
        industryIcon: <RealestateIcon />,
        industryTitle: "Real Estate",
        path: "/industries/realestate",
    },
    {
        id: 7,
        industryIcon: <CommunicationIcon />,
        industryTitle: "Communication, Media & Entertainment",
        path: "/industries/communication-media-entertainment",
    },
    {
        id: 8,
        industryIcon: <TransportIcon />,
        industryTitle: "Transport & Logistics",
        path: "/industries/trasnport-logistics",
    },
];
