import React, { useRef, useEffect } from "react";
import { gsap, Power2 } from "gsap";
// import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const ImageReveal = ({ source, imgtitle }) => {
    const ContainerRef = useRef(null);
    const revealImageRef = useRef(null);
    useEffect(() => {
        if (ContainerRef.current == null || revealImageRef.current == null) return;
        setTimeout(() => {
            let tl = gsap.timeline({
                // scrollTrigger: {
                //     trigger: ContainerRef.current,
                //     toggleActions: "restart none none reset",
                // },
            });

            tl.set(ContainerRef.current, { autoAlpha: 1 });
            tl.from(ContainerRef.current, {
                yPercent: -100,
                duration: 1.5,
                ease: Power2.out,
            });
            tl.from(revealImageRef.current, {
                yPercent: 100,
                duration: 1.5,
                delay: -1.5,
                ease: Power2.out,
            });
            return () => {
                tl.kill();
            };
        }, 1500);
    });

    return (
        <div className="image_reveal" ref={ContainerRef}>
            <img src={source} ref={revealImageRef} alt={imgtitle} title={imgtitle} />
        </div>
    );
};

export default ImageReveal;
