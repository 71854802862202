import React, { useState } from "react";
import NavbarPage from "../components/NavbarPage";
import { BsTelephone } from "react-icons/bs";
import PageHeader from "../components/PageHeader";
import { FaLinkedinIn, FaInstagram, FaTwitter, FaFacebookF } from "react-icons/fa";
import DirectAccess from "../icons/direct_access.png";
import HassleFree from "../icons/hasslefreee.png";
import Flexibleterms from "../icons/flexibleterms.png";
import OverseaseTime from "../icons/overseastime.png";
import TaglineMobile from "../components/TaglineMobile";
import { Seo } from "../components/Seo";
import seoImg from "../images/seo.jpg";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
init("LDBGI6gQ-qEcu6sI_");
const Contact = () => {
    // const dateYear = () => this.newDate().getFullYear;
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showError, setShowError] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const SubmitForm = (data, r) => {
        r.preventDefault();
        const templateId = "template_5r5uqv5";
        const serviceID = "service_xmfkl9x";
        console.log(data);
        sendFeedback(serviceID, templateId, { c_name: data.name, c_company_name: data.company_name, c_mobile: data.mobile, c_email: data.email, c_message: data.message });
        r.target.reset();
    };
    const sendFeedback = (serviceID, templateId, variables) => {
        emailjs
            .send(serviceID, templateId, variables)
            .then((res) => {
                setTimeout(() => {
                    setShowSnackbar(true);
                }, 1000);
            })
            .catch((err) => {
                setShowError(true);
                setTimeout(() => {
                    setShowError(false);
                }, 5000);
            });
    };
    return (
        <>
            <Seo
                title="Contact | Wing Coders"
                meta={[
                    {
                        property: "og:url",
                        content: "https://wingcoders.com/contact",
                    },
                    {
                        property: "og:type",
                        content: "webiste",
                    },
                    {
                        property: "og:title",
                        content: "Contact | Wing Coders",
                    },
                    {
                        property: "og:description",
                        content:
                            "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups.",
                    },
                    {
                        property: "og:image",
                        content: seoImg,
                    },
                    {
                        property: "twitter:card",
                        content: "summary_large_image",
                    },
                    {
                        property: "twitter:title",
                        content: "Contact | Wing Coders",
                    },
                    {
                        property: "twitter:description",
                        content:
                            "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups.",
                    },
                    {
                        property: "twitter:image",
                        content: seoImg,
                    },
                ]}
            />
            <NavbarPage />
            <PageHeader title="Contact" headerIcon={<BsTelephone />} tagline={<>Ready to define and execute your winning game plan?</>} />
            <div className="section_gap--small"></div>
            <TaglineMobile taglinemobile={<>Ready to define and execute your winning game plan?</>} />
            <div className="section_gap--small"></div>
            <div className="contact_form_section">
                <div className="container">
                    <div className="row flex-lg-row flex-column-reverse  gap-4 gap-lg-0 align-items-start align-items-lg-center">
                        <div className="col-12 col-lg-6">
                            <div className="form_contact_wrapper">
                                <div className="contact_form_top">
                                    <p className="subtitle">Contact</p>
                                    <h2 className="display-3">Write to us</h2>
                                </div>
                                <form id="contact_form" className="contact_form mt-4" method="post" onSubmit={handleSubmit(SubmitForm)}>
                                    <div className="contact_form_wrapper d-flex flex-column">
                                        <div className="form_input_wrapper">
                                            <input
                                                className={`form-control input_field ${errors.name ? "is-invalid" : ""}`}
                                                type="text"
                                                name="name"
                                                id="name_field"
                                                placeholder="Name"
                                                {...register("name", { required: true, maxLength: 50 })}
                                            />
                                            {errors.name?.type === "maxLength" && <div className="invalid-feedback">Max Length not exceeding 50 letters</div>}
                                            {errors.name?.type === "required" && <div className="invalid-feedback">Name required</div>}
                                        </div>
                                        <div className="form_input_wrapper">
                                            <input
                                                className={`form-control input_field ${errors.company_name ? "is-invalid" : ""}`}
                                                type="text"
                                                name="company_name"
                                                id="name_field"
                                                placeholder="Company Name"
                                                {...register("company_name", { required: true })}
                                            />
                                            {errors.company_name?.type === "required" && <div className="invalid-feedback">Company Name required</div>}
                                        </div>
                                        <div className="form_input_wrapper">
                                            <input
                                                className={`form-control input_field ${errors.email ? "is-invalid" : ""}`}
                                                type="email"
                                                name="email"
                                                id="email_field"
                                                placeholder="Email"
                                                {...register("email", {
                                                    required: true,
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    },
                                                })}
                                            />
                                            {errors.email?.type === "pattern" && <div className="invalid-feedback">Enter valid Email address</div>}
                                            {errors.email?.type === "required" && <div className="invalid-feedback">Email required</div>}
                                        </div>
                                        <div className="form_input_wrapper">
                                            <input
                                                className={`form-control input_field ${errors.mobile ? "is-invalid" : ""}`}
                                                type="text"
                                                name="mobile"
                                                id="mobile_no"
                                                placeholder="Contact No"
                                                {...register("mobile", { required: true })}
                                            />
                                            {errors.mobile?.type === "required" && <div className="invalid-feedback">Contact number required</div>}
                                        </div>
                                        <div className="form_input_wrapper">
                                            <textarea
                                                className={`form-control input_field ${errors.message ? "is-invalid" : ""}`}
                                                rows="5"
                                                name="message"
                                                id="message_field"
                                                placeholder="Requirements"
                                                {...register("message", { required: true })}
                                            />
                                            {errors.message?.type === "required" && <div className="invalid-feedback">Required</div>}
                                        </div>
                                        <div className="mt-5">
                                            <button className="primary_button">Submit</button>
                                        </div>
                                    </div>
                                    <div className=" py-3">
                                        <div className={showSnackbar ? "snack_bar text-black show" : "snack_bar text-black"}>Thank you! We will contact you shortly.</div>
                                    </div>
                                    <div className="text-danger">
                                        {showError ? (
                                            <h5 className="text-danger">
                                                <b>
                                                    Something went wrong,
                                                    <br /> Please try after sometime
                                                </b>
                                            </h5>
                                        ) : null}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="contact_icons_item_wrapper px-5">
                                <div className="contact_icons_items">
                                    {contacticonData.map((item, index) => (
                                        <div className="contact_icon_item" key={index}>
                                            <div className="contact_icon_item_svg">
                                                <img src={item.svgIcon} alt={item.title} />
                                            </div>
                                            <p className="contact_icon_item_content">{item.content}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_gap"></div>

            <section className="contact_address_section">
                <div className="container">
                    <h2 className="display-3 mb-4">Get in Touch</h2>
                    <div className="contact_address_wrapper">
                        <div className="contact_address">
                            <p className="paragraph">
                                77 Durango Drive Brampton, <br /> Ontario L6X 5G9
                            </p>
                        </div>
                        <div className="contact_address">
                            <h4 className="display-6">Reach us </h4>
                            <a href="mailto:contact@wingcoders.com">contact@wingcoders.com</a>
                        </div>
                        <div className="contact_address">
                            <h4 className="display-6">Call us </h4>
                            <a href="tel:+919962033331">+91 - 99620 33331</a> <br />
                            <a href="tel:+16477050969">+1 (647) 705-0969</a>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section_gap"></div>
            <div className="container">
                <div className="contact_footer ">
                    <div className="contact_footer_icons">
                        <a href="https://www.linkedin.com/company/wing-coders/" target="_blank" className="contact_footer_icon" rel="noopener noreferrer">
                            <FaLinkedinIn />
                        </a>
                        <a href="https://www.instagram.com/wingcoders" className="contact_footer_icon">
                            <FaInstagram />
                        </a>
                        <a href="#" className="contact_footer_icon">
                            <FaFacebookF />
                        </a>
                        {/* <a href="#" className="contact_footer_icon">
                            <FaTwitter />
                        </a> */}
                    </div>
                    <p className="paragraph">&copy;2024 Wingcoders. All Rights Reserved.</p>
                </div>
            </div>
        </>
    );
};

const contacticonData = [
    {
        id: "1",
        svgIcon: HassleFree,
        content: "Hassle free Onboarding the developers within 3 days",
    },
    {
        id: "2",
        svgIcon: OverseaseTime,
        content: "Overlap to overseas clients timezone",
    },
    {
        id: "3",
        svgIcon: DirectAccess,
        content: "Direct Access to specialized expertise",
    },
    {
        id: "4",
        svgIcon: Flexibleterms,
        content: "Flexible terms and conditions with competitive cost",
    },
];

export default Contact;
