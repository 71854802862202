import MusicStreaming from "../images/industries/communication/music.png";
import SocialMedia from "../images/industries/communication/social_media.png";
import NewsPortal from "../images/industries/communication/news_portal.png";
import StreaminSol from "../images/industries/communication/streaming.png";

export const CommunicationData = [
    {
        id: 1,
        solutionIcon: MusicStreaming,
        solutionTitle: "Music streaming application",
        solutionDesc:
            " Immerse Your Audience in a World of Melody with Wing Coders' Cutting-Edge Music Streaming Application Software. It is meticulously crafted to deliver an unparalleled audio experience, keeping your audience engaged and immersed in the rhythm of innovation.",
    },
    {
        id: 2,
        solutionIcon: SocialMedia,
        solutionTitle: "Social Media Platforms",
        solutionDesc:
            "We understand the dynamic nature of the media and entertainment landscape. Our Social Media Software is crafted to revolutionise your digital presence, engage your audience, and elevate your brand in the era of social connectivity. Amplify Your Digital Presence with our cutting–edge softwares.",
    },

    {
        id: 3,
        solutionIcon: NewsPortal,
        solutionTitle: "News Portal ",
        solutionDesc:
            "We recognize the power of timely and engaging news content in the fast-paced world of media and entertainment. Stay at the forefront of latest news stories, top headlines, and daily excerpts through News Articles and Feeds, presented in various formats including Articles, Videos, Image Galleries, Polls, and Infographics.",
    },
    {
        id: 4,
        solutionIcon: StreaminSol,
        solutionTitle: "Streaming Solutions",
        solutionDesc:
            "Step into the future of entertainment. Our streaming solutions offer a smooth and reliable platform for delivering high-quality audio and video content to a global audience.",
    },
];
