import React from "react";
import { FaLinkedinIn, FaInstagram, FaTwitter, FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";
import { WingCodersLogo, WingCodersMonogram } from "./Logo";
import { CiCircleChevRight } from "react-icons/ci";
import Nats from "../images/clients/nats_logo_white.png";

const Footer = () => {
    return (
        <>
            <div className="footer_section">
                <div className="footer_section_wrapper">
                    <div className="container">
                        <div className="footer">
                            <div className="footer_wrapper">
                                <div className="footer_left">
                                    <div>
                                        <div className="footer_logo">
                                            <WingCodersLogo />
                                        </div>
                                        <div className="footer_social_icons">
                                            <a href="https://www.linkedin.com/company/wing-coders/" target="_blank" className="footer_social_icon" rel="noopener noreferrer">
                                                <FaLinkedinIn />
                                            </a>
                                            <a href="https://www.instagram.com/wingcoders" className="footer_social_icon">
                                                <FaInstagram />
                                            </a>
                                            <a href="#" className="footer_social_icon">
                                                <FaFacebookF />
                                            </a>
                                            {/* <a href="#" className="footer_social_icon">
                                        <FaTwitter />
                                    </a> */}
                                        </div>
                                    </div>
                                    <div className="footer_logo_two">
                                        <img src={Nats} alt="Nats" title="nats" />
                                    </div>
                                </div>
                                <div className="footer_main">
                                    <div className="footer-top">
                                        <div className="footer-top-wrapper">
                                            <div className="footer_left mb-5 mb-sm-0">
                                                <div>
                                                    <div className="footer_logo">
                                                        <WingCodersLogo />
                                                    </div>
                                                    <div className="footer_social_icons">
                                                        <a
                                                            href="https://www.linkedin.com/company/wing-coders/"
                                                            target="_blank"
                                                            className="footer_social_icon"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <FaLinkedinIn />
                                                        </a>
                                                        <a href="https://www.instagram.com/wingcoders" className="footer_social_icon">
                                                            <FaInstagram />
                                                        </a>
                                                        <a href="#" className="footer_social_icon">
                                                            <FaFacebookF />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="footer_logo_two">
                                                    <img src={Nats} alt="Nats" title="nats" />
                                                </div>
                                            </div>
                                            <div className="footer-top-links mb-5 mb-sm-0">
                                                <h5 className="display-6">Quick Links</h5>
                                                <Link to="/" className="footer-top-link">
                                                    <div className="">
                                                        <span className="footer-top-link-icon">
                                                            <CiCircleChevRight />
                                                        </span>
                                                        Home
                                                    </div>
                                                </Link>
                                                <Link to="/services" className="footer-top-link">
                                                    <div className="">
                                                        <span className="footer-top-link-icon">
                                                            <CiCircleChevRight />
                                                        </span>
                                                        Services
                                                    </div>
                                                </Link>
                                                <Link to="/portfolio" className="footer-top-link">
                                                    <div className="">
                                                        <span className="footer-top-link-icon">
                                                            <CiCircleChevRight />
                                                        </span>
                                                        Portfolio
                                                    </div>
                                                </Link>
                                                <Link to="/about" className="footer-top-link">
                                                    <div className="">
                                                        <span className="footer-top-link-icon">
                                                            <CiCircleChevRight />
                                                        </span>
                                                        About
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="footer-top-links">
                                                <h5 className="display-6">Industries</h5>
                                                <Link to="/industries/health-tech" className="footer-top-link">
                                                    <div className="">
                                                        <span className="footer-top-link-icon">
                                                            <CiCircleChevRight />
                                                        </span>
                                                        Health Technology
                                                    </div>
                                                </Link>
                                                <Link to="/industries/ecommerce" className="footer-top-link">
                                                    <div className="">
                                                        <span className="footer-top-link-icon">
                                                            <CiCircleChevRight />
                                                        </span>
                                                        Ecommerce
                                                    </div>
                                                </Link>
                                                <Link to="/industries/education" className="footer-top-link">
                                                    <div className="">
                                                        <span className="footer-top-link-icon">
                                                            <CiCircleChevRight />
                                                        </span>
                                                        Education
                                                    </div>
                                                </Link>
                                                <Link to="/industries/food" className="footer-top-link">
                                                    <div className="">
                                                        <span className="footer-top-link-icon">
                                                            <CiCircleChevRight />
                                                        </span>
                                                        Food
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="footer-top-links mt-0 mt-sm-5">
                                                <Link to="/industries/fintech" className="footer-top-link">
                                                    <div className="">
                                                        <span className="footer-top-link-icon">
                                                            <CiCircleChevRight />
                                                        </span>
                                                        Fintech
                                                    </div>
                                                </Link>
                                                <Link to="/industries/realestate" className="footer-top-link">
                                                    <div className="">
                                                        <span className="footer-top-link-icon">
                                                            <CiCircleChevRight />
                                                        </span>
                                                        Real Estate
                                                    </div>
                                                </Link>
                                                <Link to="/industries/communication-media-entertainment" className="footer-top-link">
                                                    <div className="">
                                                        <span className="footer-top-link-icon">
                                                            <CiCircleChevRight />
                                                        </span>
                                                        Communication, Media & Entertainment
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="footer-top-links mt-0 mt-sm-5">
                                                <Link to="/industries/transport-logistics" className="footer-top-link">
                                                    <div className="">
                                                        <span className="footer-top-link-icon">
                                                            <CiCircleChevRight />
                                                        </span>
                                                        Transport & Logistics
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer_right">
                                        <div className="footer_right_top">
                                            <h4 className="display-4">
                                                Ready to <span className="text-primary">dicuss?</span>{" "}
                                            </h4>
                                            <Link to="/contact" className="primary_button">
                                                Contact us
                                            </Link>
                                        </div>
                                        <div className="footer_right_address_wrapper">
                                            <div className="footer_right_address">
                                                <p className="paragraph">
                                                    77 Durango Drive Brampton, <br /> Ontario L6X 5G9
                                                </p>
                                            </div>
                                            <div className="footer_right_address">
                                                <h4 className="display-6">Email </h4>
                                                <a href="mailto:contact@wingcoders.com">contact@wingcoders.com</a>
                                            </div>
                                            <div className="footer_right_address">
                                                <h4 className="display-6">Call us </h4>
                                                <a href="tel:+919962033331">+91 - 99620 33331</a> <br />
                                                <a href="tel:+16477050969">+1 (647) 705-0969</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer_bottom">
                        <div className="container">
                            <p className="footer_credits">Wingcoders &copy;2024 All rights reserved.</p>
                        </div>
                    </div>
                </div>
                <div className="footer_monogram_bg">
                    <WingCodersMonogram />
                </div>
            </div>
        </>
    );
};

export default Footer;
