import React from "react";

export const AboutFlipCardContent = [
    {
        id: 1,
        cardTitle: "Passionate ",
        cardDesc: "We always go the extra mile",
        cardClass: "red",
    },
    {
        id: 2,
        cardTitle: "Integrity ",
        cardDesc: "We’re driven to honesty, Transparent and Commitment",
        cardClass: "yellow",
    },
    {
        id: 3,
        cardTitle: " Lift Others Up",
        cardDesc: "We work for Unified success before Individual achievements",
        cardClass: "green",
    },
    {
        id: 4,
        cardTitle: <>Spread Joy &amp; Optimism</>,
        cardDesc: "We love to collaborate in pursuit of the truth.",
        cardClass: "pink",
    },
    {
        id: 5,
        cardTitle: "Professionalism ",
        cardDesc: "We treat clients the way we'd like to be treated",
        cardClass: "lightpink",
    },
];
