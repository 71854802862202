import React from "react";
import PageHeader from "../../components/PageHeader";
import TransportBanner from "../../images/industries/transport_banner.jpg";
import NavbarPage from "../../components/NavbarPage";
import { TransportIcon } from "../../icons/IndustriesIcons";
import Footer from "../../components/Footer";
import ImageReveal from "../../components/ImageReveal";
import { IntroCircleIcons } from "../../icons/HeaderIcons";
import { Link } from "react-router-dom";
import TaglineMobile from "../../components/TaglineMobile";
import { Seo } from "../../components/Seo";
import seoImg from "../../images/seo.jpg";
import { TransportData } from "./../../content/TransportContent";
const TransportLogistics = () => {
    return (
        <>
            <Seo
                title="Transport & Logistics | Wing Coders"
                meta={[
                    {
                        property: "og:url",
                        content: "https://wingcoders.com/industries/trasnport-logistics",
                    },
                    {
                        property: "og:type",
                        content: "webiste",
                    },
                    {
                        property: "og:title",
                        content: "Transport & Logistics | Wing Coders",
                    },
                    {
                        property: "og:description",
                        content:
                            "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups.",
                    },
                    {
                        property: "og:image",
                        content: seoImg,
                    },
                    {
                        property: "twitter:card",
                        content: "summary_large_image",
                    },
                    {
                        property: "twitter:title",
                        content: "Transport & Logistics | Wing Coders",
                    },
                    {
                        property: "twitter:description",
                        content:
                            "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups.",
                    },
                    {
                        property: "twitter:image",
                        content: seoImg,
                    },
                ]}
            />
            <NavbarPage />
            <PageHeader title="Transport & Logistics" headerIcon={<TransportIcon />} tagline={<>Cohesive and coordinated software development services for your business</>} />
            <TaglineMobile taglinemobile={<>Cohesive and coordinated software development services for your business</>} />

            <section className="about_intro_section">
                <div className="about_intro_section--bg">
                    <IntroCircleIcons />
                </div>
                <div className="container">
                    <div className="row d-flex align-items-center gap-4 gap-lg-0">
                        <div className="col-12 col-lg-6 gap-4 gap-lg-0">
                            <div className="about_intro_left">
                                <p className="subtitle">Industries</p>
                                <h2 className="display-3 mb-4">Transport & Logistics </h2>
                                <p className="paragraph">
                                    Gain a competitive advantage in the Transport & Logistics industry with our tailor-made IT solutions. Our offerings ensure network security,
                                    shield your CRM from viruses and malware, and prioritise data safety in the cloud, allowing you to operate confidently and eliminate the risk of
                                    manual errors.
                                </p>
                                <Link className="mt-4 primary_button" to="/contact">
                                    Leverage our developers now
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 gap-4 gap-lg-0">
                            <div className="about_intro_right">
                                <ImageReveal source={TransportBanner} imgtitle="Educational services" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="solutions_section section_vh">
                <div className="container">
                    <div className="solutions_section_head mb-5">
                        <p className="subtitle subtitle--white">Transport & Logistics</p>
                        <h2 className="display-3">
                            Our <span className="text-primary">Solutions</span>
                        </h2>
                    </div>
                    <div className="solutions_section_wrapper">
                        <div className="solution_items">
                            {TransportData.map((item, index) => (
                                <div className="solution_item" key={index}>
                                    <div className="soultion_item_icon mb-4">
                                        <img src={item.solutionIcon} alt={item.solutionTitle} title={item.solutionTitle} />
                                    </div>
                                    <div className="solution_item_content">
                                        <h2 className="display-6 mb-3">{item.solutionTitle}</h2>
                                        <p className="paragraph">{item.solutionDesc}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <div className="section_gap"></div>

            <Footer />
        </>
    );
};

export default TransportLogistics;
