import { gsap, Power4, Power2 } from "gsap/dist/gsap";
import React, { useEffect, useState } from "react";
import { WingCodersLogoHorizontal } from "./Logo";
const Preloader = ({ onComplete }) => {
    const [closed, setClosed] = useState(false);
    const closePreloader = () => {
        gsap.timeline({
            onComplete: () => {
                setClosed(true);
                document.body.classList.remove("preloading");
            },
        })
            .fromTo(".preloader-logo-wrapper", { autoAlpha: 0 }, { autoAlpha: 1, y: 0, duration: 0, ease: Power2.easeInOut })
            .fromTo(".preloader-logo-wrapper svg", { y: 100 }, { y: 0, duration: 0, ease: Power2.easeInOut })
            // .fromTo(
            //     ".preloader-logo svg .logo_text_big, .preloader-logo svg .logo_text_small",
            //     {
            //         opacity: 0,
            //         x: -20,
            //     },
            //     { opacity: 1, x: 0, duration: 1, delay: 0.1, ease: Power2.easeInOut }
            // )
            .fromTo(
                "preloader-logo svg g .logo_text_end",
                {
                    opacity: 0,
                },
                { opacity: 1, x: 0, duration: 0, delay: 0.1, ease: Power2.easeInOut }
            )

            .to(".preloader-logo", {
                autoAlpha: 0,
                delay: 0.8,
                duration: 0,
            })
            .to(".preloader", {
                autoAlpha: 0,
                duration: 0,
                ease: Power4.easeInOut,
            });
    };

    useEffect(() => {
        if (closed) onComplete();
    }, [closed]);

    useEffect(() => {
        if (closed) return;
        document.body.classList.add("preloading");
        setTimeout(closePreloader, 500);
        console.log("preloading");
        return () => {
            clearTimeout(closePreloader);
        };
    });

    return (
        <div className="preloader d-none">
            <div className="preloader-wrapper">
                <div className="preloader-logo " id="preloader--logo">
                    <div className="preloader-logo-wrapper shine">
                        {/* <img src={Logo} alt="" /> */}
                        <WingCodersLogoHorizontal />
                        {/* <PreloaderLogo /> */}
                        {/* <div className="preloader_text">
                            <p>Wing Coders</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Preloader;
