import "./styles/app.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { CSSRulePlugin } from "gsap/dist/CSSRulePlugin";
import Layout from "./pages/Layout";
import { useEffect } from "react";

function App() {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger, CSSRulePlugin);
    }, []);
    return (
        <div className="app">
            <Layout />
        </div>
    );
}

export default App;
