import React from "react";
import PageHeader from "../../components/PageHeader";
// import HealthtechBanner from "../../images/industries/health_tech_banner_1.jpg";
import HealthtechBanner from "../../images/industries/health_tech.jpg";
import { HealthTech } from "../../icons/HomeIcons";
import NavbarPage from "./../../components/NavbarPage";
import { healthtechSolutionsData, healthTechWhyChooseData } from "../../content/HealthTechContent";
import Footer from "./../../components/Footer";
import ImageReveal from "../../components/ImageReveal";
import { IntroCircleIcons } from "./../../icons/HeaderIcons";
import { Link } from "react-router-dom";
import TaglineMobile from "./../../components/TaglineMobile";
import { Seo } from "../../components/Seo";
import seoImg from "../../images/seo.jpg";
const HealthTechnology = () => {
    return (
        <>
            <Seo
                title="Health Technology | Wing Coders"
                meta={[
                    {
                        property: "og:url",
                        content: "https://wingcoders.com/industries/healthtech",
                    },
                    {
                        property: "og:type",
                        content: "webiste",
                    },
                    {
                        property: "og:title",
                        content: "Health Technology | Wing Coders",
                    },
                    {
                        property: "og:description",
                        content:
                            "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups.",
                    },
                    {
                        property: "og:image",
                        content: seoImg,
                    },
                    {
                        property: "twitter:card",
                        content: "summary_large_image",
                    },
                    {
                        property: "twitter:title",
                        content: "Health Technology | Wing Coders",
                    },
                    {
                        property: "twitter:description",
                        content:
                            "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups.",
                    },
                    {
                        property: "twitter:image",
                        content: seoImg,
                    },
                ]}
            />
            <NavbarPage />
            <PageHeader title="Health Technology" headerIcon={<HealthTech />} tagline={<>Cohesive and coordinated software development services for your business</>} />
            <TaglineMobile taglinemobile={<>Cohesive and coordinated software development services for your business</>} />
            <section className="about_intro_section">
                <div className="about_intro_section--bg">
                    <IntroCircleIcons />
                </div>
                <div className="container">
                    <div className="row d-flex align-items-center gap-4 gap-lg-0">
                        <div className="col-12 col-lg-6 gap-4 gap-lg-0">
                            <div className="about_intro_left">
                                <p className="subtitle">Industries</p>
                                <h2 className="display-3 mb-4">Health Tech</h2>
                                <p className="paragraph">
                                    Health tech is shaping up in front of our eyes with advances in digital healthcare technologies so we believe that it is mandatory to be ahead
                                    of the curve. With the increasing demand for healthcare services, there is a growing need for cutting-edge technology to enhance the delivery of
                                    care. Wingcoders, with its extensive experience in developing healthcare applications, can help meet these needs by providing innovative
                                    solutions that streamline processes and improve patient outcomes.
                                </p>
                                <Link className="mt-4 primary_button" to="/contact">
                                    Leverage our developers now
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 gap-4 gap-lg-0">
                            <div className="about_intro_right">
                                <ImageReveal source={HealthtechBanner} imgtitle="Health tech services" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="solutions_section section_vh">
                <div className="container">
                    <div className="solutions_section_head mb-5">
                        <p className="subtitle subtitle--white">health tech</p>
                        <h2 className="display-3">
                            Our <span className="text-primary">Solutions</span>
                        </h2>
                    </div>
                    <div className="solutions_section_wrapper">
                        <div className="solution_items">
                            {healthtechSolutionsData.map((item, index) => (
                                <div className="solution_item" key={index}>
                                    <div className="soultion_item_icon mb-4">{item.solutionIcon}</div>
                                    <div className="solution_item_content">
                                        <h2 className="display-6 mb-3">{item.solutionTitle}</h2>
                                        <p className="paragraph">{item.solutionDesc}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <div className="section_gap"></div>
            <section className="why_choose_section section_vh">
                <div className="container">
                    <div className="why_choose_wrapper">
                        <div className="why_choose">
                            <div className="why_choose_left">
                                <div className="why_choose_left_content">
                                    <p className="subtitle">Health Tech</p>
                                    <h4 className="display-6">Why Wing Coders for your healthcare application development needs?</h4>
                                </div>
                            </div>
                            <div className="why_choose_right">
                                <div className="why_choose_right_items">
                                    {healthTechWhyChooseData.map((item, index) => (
                                        <div className="why_choose_right_item" key={index}>
                                            <div className="why_choose_right_item_wrapper">
                                                <div className="why_choose_right_item_index">0{item.id}</div>
                                                <div className="why_choose_right_item_content">
                                                    <h3 className="display-6">{item.chooseTitle}</h3>
                                                    <p className="paragraph">{item.chooseDesc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default HealthTechnology;
