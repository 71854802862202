import React from "react";
import AboutFlipCard from "../components/AboutFlipCard";
import PageHeader from "../components/PageHeader";
import { AboutHeader, IntroCircleIcons } from "../icons/HeaderIcons";
import NavbarPage from "./../components/NavbarPage";
import Aboutntro from "../images/about_intro.jpg";
import { AboutMission } from "../icons/HomeIcons";
import { AboutVision } from "./../icons/HomeIcons";
import Footer from "../components/Footer";
import Parallax from "../components/ParallaxImage";
import ParallaxAbout from "../images/about_parallax.jpg";
import ImageReveal from "../components/ImageReveal";
import { Seo } from "../components/Seo";
import seoImg from "../images/seo.jpg";
const About = () => {
    return (
        <>
            <Seo
                title="About | Wing Coders"
                meta={[
                    {
                        property: "og:url",
                        content: "https://wingcoders.com/about",
                    },
                    {
                        property: "og:type",
                        content: "webiste",
                    },
                    {
                        property: "og:title",
                        content: "About | Wing Coders",
                    },
                    {
                        property: "og:description",
                        content:
                            "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups.",
                    },
                    {
                        property: "og:image",
                        content: seoImg,
                    },
                    {
                        property: "twitter:card",
                        content: "summary_large_image",
                    },
                    {
                        property: "twitter:title",
                        content: "About | Wing Coders",
                    },
                    {
                        property: "twitter:description",
                        content:
                            "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups.",
                    },
                    {
                        property: "twitter:image",
                        content: seoImg,
                    },
                ]}
            />
            <NavbarPage />
            <PageHeader hide_tagline="hide_tagline" title="About Us" headerIcon={<AboutHeader />} />
            <section className="about_intro_section">
                <div className="about_intro_section--bg">
                    <IntroCircleIcons />
                </div>
                <div className="container">
                    <div className="row d-flex align-items-center gap-4 gap-lg-0">
                        <div className="col-12 col-lg-6 gap-4 gap-lg-0">
                            <div className="about_intro_left">
                                <p className="subtitle">Wing Coders</p>
                                <h2 className="display-3 mb-4">
                                    Who <span className="text-primary">we are?</span>
                                </h2>
                                <p className="paragraph">
                                    At Wing Coders, as a team of thinkers and doers we get it. We create, transform, Innovate and solve as a team. We always ensure that as the
                                    company grows, so do our employees and clients. Our close-knit experienced team of researchers, analysts, designers, developers and engineers
                                    will amaze you with innovation. We are ready to give you codes, do you have concepts?
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 gap-4 gap-lg-0">
                            <div className="about_intro_right about_intro_right--landscape">
                                {/* <img src={Aboutntro} alt="" /> */}
                                <ImageReveal source={Aboutntro} imgtitle="About Wing Coders" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mission_section">
                <div className="mission_section_wrapper">
                    <div className="mission_vision mission_vision--left">
                        <div className="mission_vision_icon">
                            <AboutMission />
                        </div>
                        <div className="mision_vision_content">
                            <h3 className="display-3">
                                Our <span className="text-primary">Mission</span>{" "}
                            </h3>
                            <p className="paragraph">
                                At Wing Coders, our mission is to foster workplaces where everyone feels that they belong. We provide innovative and high-quality software solutions
                                that drive business success and improve people's lives. We are dedicated to delivering exceptional customer experiences through cutting-edge
                                technology, unparalleled expertise, and a passion for excellence.
                            </p>
                        </div>
                    </div>
                    <div className="mission_vision mission_vision--right">
                        <div className="mission_vision_icon">
                            <AboutVision />
                        </div>
                        <div className="mision_vision_content">
                            <h3 className="display-3">
                                Our <span className="text-primary">Vision</span>
                            </h3>
                            <p className="paragraph">
                                At Wing Coders, we envision a future where technology is seamlessly integrated into all aspects of life, and our role is to pioneer and lead the
                                way. Our aim is to create software that empowers individuals, businesses, and communities to reach their full potential, and we are committed to
                                making this a reality through our unwavering dedication to excellence, innovation, and customer focus.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section_gap"></div>
            <AboutFlipCard />
            <div className="section_gap"></div>
            <Parallax image={ParallaxAbout} />
            <Footer />
        </>
    );
};

export default About;
