import React, { useRef, useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { gsap, Power4 } from "gsap/dist/gsap";
import DropdownNav from "./DropdownNav";
// import { FaHome, FaPhoneAlt } from "react-icons/fa";
import { WingCodersLogo } from "./Logo";
// import { AboutHeader, IndustriesHeader, ServiceHeader } from "../icons/HeaderIcons";
// import { PortfolioHeader } from "./../icons/HeaderIcons";
import { CSSRulePlugin } from "gsap/dist/CSSRulePlugin";
// const navRoutes = [
//     { path: "/", name: "Home" },
//     { path: "/services", name: "Services" },
//     { path: "/#", name: "Industries" },
//     { path: "/#", name: "Models" },
//     { path: "/about", name: "About" },
//     { path: "/contact", name: "Contact" },
// ];
const NavbarPage = () => {
    const navbarRef = useRef([]);
    const [tlMenu, setTlMenu] = useState(null);
    const [dropdowntwo, setDropdownTwo] = useState(false);
    useEffect(() => {
        if (navbarRef.current == null) return;
        gsap.from(navbarRef.current, {
            duration: 1,
            yPercent: -100,
            stagger: 0.1,
            delay: 1,
            ease: Power4.easeInOut,
        });
    }, []);
    useEffect(() => {
        gsap.registerPlugin(CSSRulePlugin);
        // const menu_before = CSSRulePlugin.getRule(".navigation_bar::before"); //get the rule
        if (navbarRef.current === null) return;
        setTlMenu(
            gsap
                .timeline({
                    paused: true,
                })
                .set(".navbar_logo", {
                    autoAlpha: 0,
                    display: "none",
                })
                .set(".navbar_menu", {
                    autoAlpha: 0,
                    display: "none",
                })
                .to(".menu_bar", {
                    display: "none",
                    autoAlpha: 0,
                    delay: -0.5,
                    ease: Power4.easeInOut,
                })

                // .to(menu_before, {
                //     maxHeight: "100%",
                //     duration: 0.8,
                //     ease: Power4.easeInOut,
                // })

                .to(".navigation_bar", {
                    minHeight: "100vh",
                    background: "#262626",
                    duration: 0.5,
                    delay: 0.1,
                    ease: Power4.easeInOut,
                })
                .to(".navbar_menu", {
                    display: "flex",
                    // height: "100vh",
                    autoAlpha: 1,
                    x: 0,
                    stagger: 0.05,
                    duration: 0.2,
                    ease: Power4.easeInOut,
                })

                .to(".menu_bar_close", {
                    display: "flex",
                    autoAlpha: 1,
                    duration: 0.2,
                    ease: Power4.easeInOut,
                })
        );
    }, [navbarRef]);
    const handleCloseMenuB = () => {
        if (document.body.classList.contains("menu_open")) {
            document.body.classList.remove("menu_open");
            tlMenu.reverse();
            console.log("111");
        }
    };
    return (
        <>
            <div className="navigation_bar" ref={(el) => (navbarRef.current[0] = el)}>
                <div className="container">
                    <div className="navigation_bar_wrapper">
                        <div className="navbar_logo" ref={(el) => (navbarRef.current[1] = el)}>
                            <Link to="/">
                                <WingCodersLogo />
                            </Link>
                        </div>

                        <div
                            className="menu_bar"
                            onClick={() => {
                                document.body.classList.add("menu_open");
                                tlMenu?.play();
                                console.log("111");
                            }}
                            ref={(el) => (navbarRef.current[3] = el)}
                        >
                            <div className="menu_bar_line"></div>
                            <div className="menu_bar_line_1"></div>
                        </div>
                        <div
                            className=" menu_bar_close"
                            onClick={() => {
                                document.body.classList.remove("menu_open");
                                tlMenu.reverse();
                                console.log("111");
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <div className="navbar_menu" ref={(el) => (navbarRef.current[2] = el)}>
                            <div className="navbar_menu_item">
                                <NavLink to="/" className={({ isActive }) => "navbar_link" + (isActive ? " active_link" : "")} onClick={handleCloseMenuB}>
                                    <div className="navbar_menu_item_inner">
                                        {/* <span>
                                            <FaHome />
                                        </span> */}
                                        Home
                                    </div>
                                </NavLink>
                            </div>
                            <div className="navbar_menu_item">
                                <NavLink to="/services" className={({ isActive }) => "navbar_link" + (isActive ? " active_link" : "")} onClick={handleCloseMenuB}>
                                    <div className="navbar_menu_item_inner">
                                        {/* <span>
                                            <ServiceHeader />
                                        </span> */}
                                        Services
                                    </div>
                                </NavLink>
                            </div>
                            <div className="navbar_menu_item" onMouseEnter={() => setDropdownTwo(true)} onMouseLeave={() => setDropdownTwo(false)}>
                                <div className="navbar_link">
                                    <div className="navbar_menu_item_inner">
                                        {/* <span>
                                            <IndustriesHeader />
                                        </span> */}
                                        Industries
                                    </div>
                                </div>
                                <DropdownNav dropdowntwo={dropdowntwo} handleCloseMenuB={handleCloseMenuB} />
                            </div>
                            <div className="navbar_menu_item">
                                <NavLink to="/portfolio" className={({ isActive }) => "navbar_link" + (isActive ? " active_link" : "")} onClick={handleCloseMenuB}>
                                    <div className="navbar_menu_item_inner">
                                        {/* <span>
                                            <PortfolioHeader />
                                        </span> */}
                                        Portfolio
                                    </div>
                                </NavLink>
                            </div>
                            <div className="navbar_menu_item">
                                <NavLink to="/about" className={({ isActive }) => "navbar_link" + (isActive ? " active_link" : "")} onClick={handleCloseMenuB}>
                                    <div className="navbar_menu_item_inner">
                                        {/* <span>
                                            <AboutHeader />
                                        </span> */}
                                        About
                                    </div>
                                </NavLink>
                            </div>
                            <div className="navbar_menu_item">
                                <NavLink to="/contact" className={({ isActive }) => "navbar_link" + (isActive ? " active_link" : "")} onClick={handleCloseMenuB}>
                                    <div className="navbar_menu_item_inner">
                                        {/* <span>
                                            <FaPhoneAlt />
                                        </span> */}
                                        Contact
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NavbarPage;
