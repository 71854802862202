import React from "react";
import PageHeader from "../../components/PageHeader";
import FoodBanner from "../../images/industries/food_banner.jpg";
import { FoodTech } from "../../icons/HomeIcons";
import NavbarPage from "./../../components/NavbarPage";
import { FoodSolutionsData } from "../../content/FoodContent";
import Footer from "./../../components/Footer";
import ImageReveal from "./../../components/ImageReveal";
import { IntroCircleIcons } from "./../../icons/HeaderIcons";
import { Link } from "react-router-dom";
import TaglineMobile from "./../../components/TaglineMobile";
import { Seo } from "../../components/Seo";
import seoImg from "../../images/seo.jpg";
const Food = () => {
    return (
        <>
            <Seo
                title="Food | Wing Coders"
                meta={[
                    {
                        property: "og:url",
                        content: "https://wingcoders.com/industries/food",
                    },
                    {
                        property: "og:type",
                        content: "webiste",
                    },
                    {
                        property: "og:title",
                        content: "Food | Wing Coders",
                    },
                    {
                        property: "og:description",
                        content:
                            "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups.",
                    },
                    {
                        property: "og:image",
                        content: seoImg,
                    },
                    {
                        property: "twitter:card",
                        content: "summary_large_image",
                    },
                    {
                        property: "twitter:title",
                        content: "Food | Wing Coders",
                    },
                    {
                        property: "twitter:description",
                        content:
                            "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups.",
                    },
                    {
                        property: "twitter:image",
                        content: seoImg,
                    },
                ]}
            />
            <NavbarPage />
            <PageHeader title="Food" headerIcon={<FoodTech />} tagline={<>Cohesive and coordinated software development services for your business</>} />
            <TaglineMobile taglinemobile={<>Cohesive and coordinated software development services for your business</>} />

            <section className="about_intro_section">
                <div className="about_intro_section--bg">
                    <IntroCircleIcons />
                </div>
                <div className="container">
                    <div className="row d-flex align-items-center gap-4 gap-lg-0">
                        <div className="col-12 col-lg-6 gap-4 gap-lg-0">
                            <div className="about_intro_left">
                                <p className="subtitle">Industries</p>
                                <h2 className="display-3 mb-4">Food </h2>
                                <p className="paragraph">
                                    Shaping the future of education. Our goal is to provide cutting-edge technology to connect faculty & students with any device from anywhere
                                    using our easy and user-friendly e-learning solutions with the digital technology engulfing our education system.
                                </p>
                                <Link className="mt-4 primary_button" to="/contact">
                                    Leverage our developers now
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 gap-4 gap-lg-0">
                            <div className="about_intro_right">
                                <ImageReveal source={FoodBanner} imgtitle="Food tech services" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="solutions_section section_vh">
                <div className="container">
                    <div className="solutions_section_head mb-5">
                        <p className="subtitle subtitle--white">Food</p>
                        <h2 className="display-3">
                            Our <span className="text-primary">Solutions</span>
                        </h2>
                    </div>
                    <div className="solutions_section_wrapper">
                        <div className="solution_items">
                            {FoodSolutionsData.map((item, index) => (
                                <div className="solution_item" key={index}>
                                    <div className="soultion_item_icon mb-4">{item.solutionIcon}</div>
                                    <div className="solution_item_content">
                                        <h2 className="display-6 mb-3">{item.solutionTitle}</h2>
                                        <p className="paragraph">{item.solutionDesc}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <div className="section_gap"></div>

            <Footer />
        </>
    );
};

export default Food;
