import React from "react";
import PortfolioSlider_1 from "../images/portfolio/portfolio_1/portfolio_slider_1.png";
import PortfolioSlider_2 from "../images/portfolio/portfolio_1/portfolio_slider_2.png";
import PortfolioSlider_3 from "../images/portfolio/portfolio_1/portfolio_slider_3.png";
import PortfolioSlider_4 from "../images/portfolio/portfolio_1/portfolio_slider_4.png";
import PortfolioSlider_5 from "../images/portfolio/portfolio_1/portfolio_slider_5.png";
import PortfolioSlider_b_1 from "../images/portfolio/portfolio_2/portfolio_2_a.png";
import PortfolioSlider_b_2 from "../images/portfolio/portfolio_2/portfolio_2_b.png";
import PortfolioSlider_b_3 from "../images/portfolio/portfolio_2/portfolio_2_c.png";
import PortfolioSlider_b_4 from "../images/portfolio/portfolio_2/portfolio_2_d.png";
import PortfolioSlider_b_5 from "../images/portfolio/portfolio_2/portfolio_2_e.png";
export const PortfolioData = [
    {
        id: 1,
        cName: "portfolio_item",
        title: "Quick App",
        desc: "Quick is an online food ordering application with free delivery that allows users to select and order food based on their preference, the admin panel allows the admin to view the list of orders placed by the user.",
        sliderImages: [
            {
                sliderImg: PortfolioSlider_1,
            },
            {
                sliderImg: PortfolioSlider_2,
            },
            {
                sliderImg: PortfolioSlider_3,
            },
            {
                sliderImg: PortfolioSlider_4,
            },
            {
                sliderImg: PortfolioSlider_5,
            },
        ],
    },
    {
        id: 2,
        cName: "portfolio_item portfolio_item--reverse",
        title: "Firstcry App",
        desc: "The Firstcry app is your one-stop solution for baby care which provides services of product shopping,baby tracker to continuously monitor the baby's growth and health, remainder alert of upcomming vacination and proper record of vaccination details, A community of parents and gaurdians where they can communicate with other parents or gaurdians, Baby care tips and guides through blogs and also doctor consultation of online and offline booking.",
        sliderImages: [
            {
                sliderImg: PortfolioSlider_b_1,
            },
            {
                sliderImg: PortfolioSlider_b_2,
            },
            {
                sliderImg: PortfolioSlider_b_3,
            },
            {
                sliderImg: PortfolioSlider_b_4,
            },
            {
                sliderImg: PortfolioSlider_b_5,
            },
        ],
    },
];
