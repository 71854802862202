import React, { Fragment, useState, useRef } from "react";
import { Tab } from "@headlessui/react";
// import { gsap } from "gsap/dist/gsap";
import { Transition } from "react-transition-group";

import { homeServiceContent } from "../content/HomeServiceContentData";
import { BsArrowLeft } from "react-icons/bs";
// import { MdDeveloperMode } from "react-icons/md";

const HomeService = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const TabRef = useRef([]);
    const [inProp, setInProp] = useState(false);
    const nodeRef = useRef(null);

    return (
        <>
            <section
                className="
             home_service_section"
            >
                <div className="container">
                    <div className="home_service_intro ">
                        <h6 className="subtitle subtitle--black">Wing coders</h6>
                        <h2 className="display-3">
                            <span className="text-primary">Services</span> We Provide
                        </h2>
                    </div>
                    <Tab.Group active={activeIndex} onChange={setActiveIndex}>
                        <div className="home_service">
                            <div className="home_service_left">
                                <div className="home_service_left_wrapper">
                                    <div className="home_service_items_wrapper">
                                        <Tab.List className="home_service_items">
                                            {homeServiceContent.map((item, index) => (
                                                <Tab className="home_service_items_wrap" key={index}>
                                                    {({ selected }) => (
                                                        <div className={selected ? `home_service_item ${item.class} active` : `home_service_item ${item.class}`}>
                                                            <div className={selected ? "home_service_item_wrapper active" : "home_service_item_wrapper"}>
                                                                <div className="home_service_item_icon">{item.iconVector}</div>
                                                                <div className="home_service_item_title">{item.title}</div>
                                                                <div className="home_service_item_index">0{item.id}</div>
                                                            </div>
                                                            <div className={selected ? "home_service_icon_button active" : "home_service_icon_button"}>
                                                                <BsArrowLeft />
                                                            </div>
                                                        </div>
                                                    )}
                                                </Tab>
                                            ))}
                                        </Tab.List>
                                    </div>
                                </div>
                            </div>
                            <div className="home_service_right">
                                <div className="home_service_right_wrapper_bg"></div>
                                <Tab.Panels as={Fragment}>
                                    {homeServiceContent.map((item, index) => (
                                        <Transition nodeRef={nodeRef} in={inProp} timeout={2000} key={index}>
                                            <Tab.Panel className={`home_service_right_wrapper ${item.rowColClass}`} key={index}>
                                                <div className="home_service_right_content_wrapper" ref={(el) => (TabRef.current[index] = el)}>
                                                    <div className="home_service_right_content">
                                                        <h3 className="display-4">{item.title}</h3>
                                                        <p className="paragraph">{item.serviceDesc}</p>
                                                    </div>
                                                    <div className="">
                                                        <h5 className="display-6 mb-3">{item.toolsDesc}</h5>
                                                        <ContentTab toolsIcons={item.toolsIcons} />
                                                    </div>
                                                </div>
                                            </Tab.Panel>
                                        </Transition>
                                    ))}
                                </Tab.Panels>
                            </div>
                        </div>
                    </Tab.Group>
                </div>
            </section>
        </>
    );
};

export default HomeService;

const ContentTab = ({ toolsIcons }) => {
    return (
        <>
            <div className="home_service_right_col">
                {toolsIcons.map((item, index) => {
                    return (
                        <div className="home_service_right_icons" key={index}>
                            <div className="home_service_right_icon">{item.iconVector}</div>
                            <h6 className="display-6">{item.iconDesc}</h6>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
