import React from "react";
import { AboutFlipCardContent } from "./../content/AboutPageContent";
const AboutFlipCard = () => {
    return (
        <div className=" text-center m-auto">
            <div className="container">
                <div className="about_flip_title_wrapper text-center mb-5">
                    <p className="subtitle justify-content-center mb-2">Wing Coders</p>
                    <h2 className="display-3">
                        Our <span className="text-primary">Core Values</span>
                    </h2>
                </div>
                <div className="flip_cards">
                    {AboutFlipCardContent.map((item, index) => (
                        <div className="flip_card" key={index}>
                            <div className="flip_card_wrapper">
                                <div className={`flip_card_front ${item.cardClass}`}>
                                    <h5 className="">{item.cardTitle}</h5>
                                    <div className="flip_card_front_index">0{item.id}</div>
                                </div>
                                <div className="flip_card_back">
                                    <p className="paragraph">{item.cardDesc}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AboutFlipCard;
