import { MdDeveloperMode } from "react-icons/md";
import { AgileDevelopment, ClientCentric, DevelopmentTeam, Support, Databackup, DataProtection, QualityDeliver, Enhancement } from "./../icons/HomeFeaturesIcons";

export const homeOurFeatures = [
    {
        id: 1,
        featureIcon: <Enhancement />,
        featureTitle: <>Innovative Approach</>,
        featureDesc: "We craft the client's needs on web, mobile, and blockchain to tailor the solutions and enhance growth of the businesses.",
    },
    {
        id: 2,
        featureIcon: <AgileDevelopment />,
        featureTitle: "Agile Development",
        featureDesc: "We follow the Agile Development process that helps us to deliver the project with utmost quality and solid product for reliable and scalable business.",
    },
    {
        id: 3,
        featureIcon: <DevelopmentTeam />,
        featureTitle: (
            <>
                Dedicated Development <br />
                Team
            </>
        ),
        featureDesc: "Our skilled team leverage projects to provide best results.The team dedicates their every effort and does not look back until it's accomplished.",
    },
    {
        id: 4,
        featureIcon: <Support />,
        featureTitle: (
            <>
                Strong Project
                <br />
                Management
            </>
        ),
        featureDesc: "We are always there to assist our clients in every possible way to meet client's expectations and end needs.",
    },
    // {
    //     id: 5,
    //     featureIcon: <Databackup />,
    //     featureTitle: (
    //         <>
    //             Data <br /> Backups
    //         </>
    //     ),
    //     featureDesc:
    //         "The backups are the best options and we carefully maintain our clients project backups to deal with misfortunes in the best manner.",
    // },
    // {
    //     id: 6,
    //     featureIcon: <DataProtection />,
    //     featureTitle: (
    //         <>
    //             Data <br />
    //             Protection
    //         </>
    //     ),
    //     featureDesc:
    //         "By signing an NDA, our company ensures the utmost safety of all your private data. We deliver best-rated products to maintain confidentiality.",
    // },
    // {
    //     id: 7,
    //     featureIcon: <QualityDeliver />,
    //     featureTitle: (
    //         <>
    //             Quality <br />
    //             Deliverance
    //         </>
    //     ),
    //     featureDesc:
    //         "We believe in delivering quality products to our clients by assuring all their project specifications at the best industry competitive price.",
    // },
    // {
    //     id: 8,
    //     featureIcon: <Enhancement />,
    //     featureTitle: "Enhancement",
    //     featureDesc:
    //         "During Discovery, Designing and Development phases, our technical team is always there to provide suggestions and edits to improvise their product in the best possible manner.",
    // },
];
