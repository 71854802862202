import React from "react";

import Aws from "../images/clients/aws_partner.png";
import Azure from "../images/clients/azure_partner.png";
import Clutch from "../images/clients/clutch_partner.png";
import GooglePartner from "../images/clients/google_partner.png";
import Microsoft from "../images/clients/microsoft_partner.png";
import Oracle from "../images/clients/oracle_partner.png";
import Salesforce from "../images/clients/salesforce_partner.png";
import Adobe from "../images/clients/adobe_partner.png";
import Startup from "../images/clients/startup_tn.png";
import Nats from "../images/clients/nats.png";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
const HomePartners = () => {
    return (
        <>
            <div className="home_clients_section">
                <div className="container">
                    <div className="home_clients_wrapper">
                        <h2 className="display-3 mb-3 mb-md-5">
                            Recognitions <span className="text-primary">& Partnerships</span>
                        </h2>
                        <div className="home_clients_slider_wrapper">
                            <Swiper
                                modules={[Autoplay]}
                                loop={true}
                                spaceBetween={40}
                                freeMode={true}
                                freeModeMomentum={false}
                                speed={6000}
                                autoplay={{ delay: 0, disableOnInteraction: false }}
                                breakpoints={{
                                    0: {
                                        // width: 576,
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        // width: 768,
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                    },
                                    990: {
                                        // width: 990,
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    1100: {
                                        // width: 990,
                                        slidesPerView: 4,
                                        spaceBetween: 40,
                                    },
                                }}
                                className="home_clients_slider"
                            >
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={GooglePartner} alt="Quick" title="Quick" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={Aws} alt="Quick" title="Quick" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={Azure} alt="Nkb" title="Nkb" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={Clutch} alt="Raak College" title="Raak College" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={Microsoft} alt="Raak College" title="Raak College" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={Oracle} alt="Raak College" title="Raak College" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={Salesforce} alt="Raak" title="Raak" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={Adobe} alt="Bharat University" title="Bharat University" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={Startup} alt="Bharat University" title="Bharat University" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={Nats} alt="Bharat University" title="Bharat University" />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomePartners;
