import ConnectingSoft from "../images/industries/fintech/connect_software.png";
import LoanLending from "../images/industries/fintech/loan_lending.png";
import ExpenseManage from "../images/industries/fintech/expense_management.png";
import DigitalWallet from "../images/industries/fintech/digital_expense.png";
import PaymentGateway from "../images/industries/fintech/payment_gateway.png";

export const FintechData = [
    {
        id: 1,
        solutionIcon: ConnectingSoft,
        solutionTitle: "Marketplace Connecting Software ",
        solutionDesc:
            " We create marketplace lending solutions that facilitate the connection between loan originators and institutional lenders, enabling loan borrowers to access optimal deals through our software, which leverages industry connections.",
    },
    {
        id: 2,
        solutionIcon: LoanLending,
        solutionTitle: "One - to - One Loan Lending",
        solutionDesc:
            "It supports the transfer of loans from one individual to another, eliminating the reliance on traditional banking channels. It streamlines the loan processing functions without requiring conventional banking assistance and participation.",
    },

    {
        id: 3,
        solutionIcon: ExpenseManage,
        solutionTitle: "Expenses Management System",
        solutionDesc:
            "Wing Coder's Expense Management System is the key to streamlining your financial processes. Take control of your expenses, boost productivity, and make informed financial decisions. Our solution simplifies the complex task of tracking and managing expenses, empowering businesses to achieve efficiency and transparency.",
    },
    {
        id: 4,
        solutionIcon: DigitalWallet,
        solutionTitle: "Digital Wallet Solution",
        solutionDesc:
            "Wing Coders' Digital Wallet Solution is the gateway to a cashless and connected future. Empower your users with a secure, user-friendly, and feature-rich digital wallet experience. Our innovative platform revolutionises the way users manage and transact their funds, providing a secure and convenient digital experience.",
    },
    {
        id: 5,
        solutionIcon: PaymentGateway,
        solutionTitle: "Custom Payment Gateways",
        solutionDesc:
            " It is a key to a personalised and efficient payment processing system. Enhance your brand, boost security, and streamline transactions with a tailored solution crafted just for you. Tailored to meet your unique business needs, our innovative gateway ensures seamless and secure transactions for both you and your customers.",
    },
];
