import React from "react";
import { Ehr, Telemedicine, Cdss, PatientManage, SecureDataManagement, ExistingSystem } from "../icons/IndustriesIcons";
export const healthtechSolutionsData = [
    {
        id: 1,
        solutionIcon: <Ehr />,
        solutionTitle: "Electronic Health Records (EHR) management",
        solutionDesc:
            " Our healthcare applications allow for the secure storage and retrieval of patient information, ensuring that healthcare providers have access to comprehensive and up-to-date patient records.",
    },
    {
        id: 2,
        solutionIcon: <Telemedicine />,
        solutionTitle: "Telemedicine",
        solutionDesc:
            "Our telemedicine solutions enable healthcare providers to offer virtual consultations to patients, reducing the need for in-person visits and increasing access to care",
    },
    {
        id: 3,
        solutionIcon: <Cdss />,
        solutionTitle: "Clinical Decision Support Systems (CDSS)",
        solutionDesc:
            "Our CDSS solutions use advanced algorithms and data analytics to support healthcare providers in making informed treatment decisions, improving patient outcomes and reducing the risk of medical errors.",
    },
    {
        id: 4,
        solutionIcon: <PatientManage />,
        solutionTitle: "Patient engagement",
        solutionDesc:
            "Our applications empower patients to take control of their health by providing them with access to their health records, appointment scheduling, and other important health information.",
    },

    {
        id: 5,
        solutionIcon: <SecureDataManagement />,
        solutionTitle: "Secure data management",
        solutionDesc:
            "Our healthcare applications are designed with privacy and security in mind, ensuring that sensitive patient information is protected against unauthorised access and breaches.",
    },
    {
        id: 6,
        solutionIcon: <ExistingSystem />,
        solutionTitle: "Integration with existing systems",
        solutionDesc:
            "Our applications can be seamlessly integrated with existing healthcare systems, ensuring a smooth transition to a new technology platform.",
    },
];

export const healthTechWhyChooseData = [
    {
        id: 1,
        chooseTitle: "Experienced team",
        chooseDesc:
            "Our team of healthcare application developers has extensive experience in the development of healthcare solutions and is well-versed in the latest technology trends and best practices.",
    },
    {
        id: 2,
        chooseTitle: "Customizable solutions",
        chooseDesc:
            "We understand that every healthcare organisation is unique, and we work closely with our clients to develop customised solutions that meet their specific needs.",
    },
    {
        id: 3,
        chooseTitle: "Cost-effective solutions",
        chooseDesc:
            "Our healthcare application development services are designed to be cost-effective, providing our clients with a high return on investment.",
    },
    {
        id: 4,
        chooseTitle: "Exceptional customer support",
        chooseDesc:
            "We are committed to providing our clients with exceptional customer support, ensuring that they have the resources they need to make the most of their new technology solutions.",
    },
];
