import React from "react";
import { RiDoubleQuotesL } from "react-icons/ri";

const TaglineMobile = ({ taglinemobile = "" }) => {
    return (
        <>
            <div className="tagline_mobile">
                <div className="tagline_mobile_wrapper">
                    <span className="tagline_mobile_quote">
                        <RiDoubleQuotesL />
                    </span>
                    <h6 className="display-5">{taglinemobile}</h6>
                </div>
            </div>
        </>
    );
};

export default TaglineMobile;
