import React, { useRef, useEffect } from "react";
import { gsap, Power4 } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const HomeCounter = () => {
    const CounterRef = useRef([]);
    const counerImg = useRef(null);
    gsap.registerPlugin(ScrollTrigger);
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.from(CounterRef.current, {
            textContent: 0,
            snap: { textContent: 1 },
            duration: 1,
            ease: Power4.in,
            scrollTrigger: {
                trigger: document.querySelector(".counter_section"),
                start: "top 70%",
                toggleActions: "play none repeat reset",
            },
        });
        // if (counerImg.current == null) return;
        // const tween = gsap.to(counerImg.current, {
        //     backgroundPosition: `50% ${-window.innerHeight / 2}px`,
        //     ease: "none",
        //     scrollTrigger: {
        //         trigger: counerImg.current,
        //         scrub: true,
        //     },
        // });

        // return () => {
        //     tween.kill();
        // };
    }, []);

    return (
        <section className="counter_section ">
            <div className="counter_section_wrapper">
                <div className="counter_section_left">
                    <h6 className="subtitle subtitle--black">Milestones</h6>
                    <h2 className="display-3 mb-4">
                        Our <span className="text-primary">Progress</span>
                    </h2>
                    <div className="counters">
                        {CounterContent.map((item, index) => (
                            <div className="counter_item" key={index}>
                                <div className="counter_item_count_bg">
                                    <span>{item.count}</span>
                                </div>
                                <div className="counter_item_wrapper d-md-flex flex-column gap-1 text-start ">
                                    <div className="counter_item_count">
                                        <span ref={(el) => (CounterRef.current[index] = el)}>{item.count}</span> <span className="">{item.id === 4 ? "%" : "+"}</span>
                                    </div>
                                    <div className="counter_item_text">{item.countTitle}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="counter_section_right">
                    <div ref={counerImg} className="counter_section_right_parallax"></div>
                </div>
            </div>
        </section>
    );
};

export default HomeCounter;

const CounterContent = [
    {
        id: 1,
        count: "50",
        countTitle: <>Developers</>,
    },
    {
        id: 2,
        count: "30",
        countTitle: (
            <>
                Happy <br /> Clients
            </>
        ),
    },
    {
        id: 3,
        count: "70",
        countTitle: (
            <>
                Milestones <br /> Achieved
            </>
        ),
    },
    {
        id: 4,
        count: "100",
        countTitle: (
            <>
                Client Retention <br /> Rate
            </>
        ),
    },
];
