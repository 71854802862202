import React from "react";
import { SecurePayment, UserFriendlyInterface, ProductCatalogue, OrderManagement, AnalyticsReport, MobileOptimize } from "./../icons/IndustriesIcons";
export const EcommerceSolutionsData = [
    {
        id: 1,
        solutionIcon: <UserFriendlyInterface />,
        solutionTitle: "User-friendly interface",
        solutionDesc:
            "Our e-commerce applications are designed with a user-friendly interface that makes shopping easy and enjoyable. We use the latest design trends and user experience techniques to create a seamless shopping experience for your customers.",
    },
    {
        id: 2,
        solutionIcon: <SecurePayment />,
        solutionTitle: "Secure payment gateway",
        solutionDesc:
            "We understand the importance of secure online transactions, which is why we integrate secure payment gateways into our e-commerce applications. Our solutions are designed to protect sensitive customer information and ensure safe and secure transactions.",
    },

    {
        id: 3,
        solutionIcon: <ProductCatalogue />,
        solutionTitle: "Customizable product catalogue",
        solutionDesc:
            "Our e-commerce applications are designed to be highly customizable, allowing you to easily manage your product catalogue, including categories, products, and attributes.",
    },
    {
        id: 4,
        solutionIcon: <OrderManagement />,
        solutionTitle: "Order management system ",
        solutionDesc:
            "Our e-commerce applications come equipped with an advanced order management system that allows you to easily manage orders, track inventory, and fulfil orders in a timely and efficient manner.",
    },
    {
        id: 5,
        solutionIcon: <MobileOptimize />,
        solutionTitle: "Mobile optimization ",
        solutionDesc:
            "With more and more customers shopping on mobile devices, it's crucial that your e-commerce application is optimised for mobile. Our solutions are designed to provide a smooth and seamless mobile shopping experience, ensuring that your customers can shop from anywhere, at any time.",
    },
    {
        id: 4,
        solutionIcon: <AnalyticsReport />,
        solutionTitle: "Analytics and reporting ",
        solutionDesc:
            "Our e-commerce applications come with advanced analytics and reporting capabilities, allowing you to track and analyse key performance metrics, such as sales, customer engagement, and product performance.",
    },
];
