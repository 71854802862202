import React from "react";
import Quick from "../images/clients/quick.png";
import Nkb from "../images/clients/nkb.png";
import BhartUniversity from "../images/clients/bharat_university.png";
import Raak from "../images/clients/raak_college.png";
import Raakn from "../images/clients/raakn.png";
import Saveetha from "../images/clients/saveetha.png";
import Fyafit from "../images/clients/fyafit.png";
import Bhavaconsulting from "../images/clients/bhava_consulting.png";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const HomeClients = () => {
    return (
        <>
            <div className="home_clients_section">
                <div className="container">
                    <div className="home_clients_wrapper">
                        <h2 className="display-3 mb-3 mb-md-5">
                            Our <span className="text-primary">Clients</span>
                        </h2>
                        <div className="home_clients_slider_wrapper">
                            <Swiper
                                modules={[Autoplay]}
                                loop={true}
                                spaceBetween={40}
                                slidesPerView="4"
                                freeMode={true}
                                freeModeMomentum={false}
                                speed={6000}
                                autoplay={{ delay: 0, disableOnInteraction: false }}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                    },
                                    990: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    1100: {
                                        slidesPerView: 4,
                                        spaceBetween: 40,
                                    },
                                }}
                                className="home_clients_slider "
                            >
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={Quick} alt="Quick" title="Quick" />
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={Nkb} alt="Nkb" title="Nkb" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={Saveetha} alt="Raak College" title="Raak College" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={Fyafit} alt="Raak College" title="Raak College" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={Raakn} alt="Raak College" title="Raak College" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={Raak} alt="Raak" title="Raak" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={BhartUniversity} alt="Bharat University" title="Bharat University" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="home_clients_item">
                                        <img src={Bhavaconsulting} alt="Bharat University" title="Bharat University" />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeClients;
