import React, { useRef, useEffect } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
// import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { homeOurFeatures } from "../content/HomeFeaturesContent";
// import { gsap, Power4 } from "gsap/dist/gsap";
import "swiper/css";
// import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import "swiper/css/bundle";
const HomeFeatures = () => {
    return (
        <>
            <section className="home_features_section">
                <div className="section_vh">
                    <div className="container">
                        <div className="home_features mb-5">
                            <h6 className="subtitle subtitle--white">Our features</h6>
                            <h2 className="display-3">
                                What Are We <span className="text-primary">Good at?</span>
                            </h2>
                        </div>
                    </div>
                    <div className="container">
                        {/* <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                            // loop={true}
                            spaceBetween={40}
                            slidesPerView={4}
                            speed={1500}
                            grabCursor={true}
                            // autoplay={{ delay: 5000 }}
                            // navigation={{
                            //     nextEl: ".testimonial_navigation .next-button",
                            //     prevEl: ".testimonial_navigation .prev-button",
                            // }}
                            // scrollbar={{ draggable: true }}
                            breakpoints={{
                                // when window width is >= 640px
                                640: {
                                    width: 640,
                                    slidesPerView: 1,
                                },
                                // when window width is >= 768px
                                768: {
                                    width: 768,
                                    slidesPerView: 2.5,
                                },
                                1024: {
                                    width: 1024,
                                    slidesPerView: 3,
                                },
                                1200: {
                                    width: 1200,
                                    slidesPerView: 4,
                                },
                            }}
                            className="home_features_slider"
                        >
                            {homeOurFeatures.map((item, index) => (
                                <SwiperSlide className="home_features_slider_item" key={index}>
                                    <div className="home_features_slider_item_wrapper">
                                        <div className="home_features_slider_item_title">
                                            <h4 className=" mb-4">{item.featureTitle}</h4>
                                        </div>
                                        <p className="paragraph">{item.featureDesc}</p>
                                        <div className="home_features_slider_item_icon">{item.featureIcon}</div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper> */}
                        <div className="home_features_items">
                            {homeOurFeatures.map((item, index) => (
                                <div className="home_features_item" key={index}>
                                    <div className="home_features_item_wrapper">
                                        <div className="home_features_item_title">
                                            <h4 className=" mb-4">{item.featureTitle}</h4>
                                        </div>
                                        <p className="paragraph">{item.featureDesc}</p>
                                        <div className="home_features_item_icon">{item.featureIcon}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HomeFeatures;
