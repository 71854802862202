import React from "react";
import { FoodPayment, OrderTrackFood, RestuarantRecommend, WideRangeCuisines, FoodUserFriendly } from "./../icons/IndustriesIcons";
export const FoodSolutionsData = [
    {
        id: 1,
        solutionIcon: <FoodUserFriendly />,
        solutionTitle: "User-friendly interface",
        solutionDesc: "A simple and intuitive interface that makes it easy for users to place orders, choose from a wide range of food items and track their order status.",
    },
    {
        id: 2,
        solutionIcon: <WideRangeCuisines />,
        solutionTitle: "Wide range of cuisines",
        solutionDesc: "The application offers a wide range of cuisines to choose from, such as Indian, Chinese, Italian, Mexican, and many more.",
    },
    {
        id: 3,
        solutionIcon: <RestuarantRecommend />,
        solutionTitle: "Restaurant recommendations",
        solutionDesc: "Based on users' location and previous orders, the application can recommend the best restaurants and food trucks in the area.",
    },
    {
        id: 4,
        solutionIcon: <FoodPayment />,
        solutionTitle: "Payment options ",
        solutionDesc: "The application supports multiple payment options such as credit/debit cards, net banking, and cash on delivery.",
    },
    {
        id: 4,
        solutionIcon: <OrderTrackFood />,
        solutionTitle: "Order tracking",
        solutionDesc: "The application provides real-time updates on the status of an order, so users can know exactly when their food will arrive.",
    },
];
