import React from "react";
import PageHeader from "../components/PageHeader";
import NavbarPage from "./../components/NavbarPage";
import { PortfolioHeader } from "./../icons/HeaderIcons";
import { Link } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import { PortfolioData } from "./../content/PortfolioPageContent";
import Footer from "../components/Footer";
import TaglineMobile from "../components/TaglineMobile";
import { Seo } from "../components/Seo";
import seoImg from "../images/seo.jpg";
const Portfolio = () => {
    return (
        <>
            <Seo
                title="Portfolio | Wing Coders"
                meta={[
                    {
                        property: "og:url",
                        content: "https://wingcoders.com/portfolio",
                    },
                    {
                        property: "og:type",
                        content: "webiste",
                    },
                    {
                        property: "og:title",
                        content: "Portfolio | Wing Coders",
                    },
                    {
                        property: "og:description",
                        content:
                            "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups.",
                    },
                    {
                        property: "og:image",
                        content: seoImg,
                    },
                    {
                        property: "twitter:card",
                        content: "summary_large_image",
                    },
                    {
                        property: "twitter:title",
                        content: "Portfolio | Wing Coders",
                    },
                    {
                        property: "twitter:description",
                        content:
                            "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups.",
                    },
                    {
                        property: "twitter:image",
                        content: seoImg,
                    },
                ]}
            />
            <NavbarPage />
            <PageHeader title="Portfolio" headerIcon={<PortfolioHeader />} tagline={<>We don't create apps, We create experiences</>} />
            <TaglineMobile taglinemobile={<>We don't create apps, We create experiences</>} />
            <div className="section_gap"></div>
            {PortfolioData.map((item, index) => (
                <section className="portfolio_section" key={index}>
                    <div className={item.cName}>
                        <div className="portfolio_item_left">
                            <div className="portfolio_item_left_content">
                                <h3 className="display-3 mb-4">{item.title}</h3>
                                <p className="paragraph pb-5">{item.desc}</p>
                                <div>
                                    <Link to="/contact" className="primary_button">
                                        Enquire Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="portfolio_item_right">
                            <SliderItem sliderImages={item.sliderImages} />
                        </div>
                    </div>
                </section>
            ))}
            <div className="section_gap"></div>
            <Footer />
        </>
    );
};

const SliderItem = ({ sliderImages }) => {
    return (
        <React.Fragment>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                loop={true}
                spaceBetween={80}
                slidesPerView={3.4}
                speed={2000}
                autoplay={{ delay: 3000 }}
                pagination={{ clickable: true }}
                breakpoints={{
                    0: {
                        // width: 576,
                        slidesPerView: 2.1,
                        spaceBetween: 60,
                    },
                    768: {
                        // width: 768,
                        slidesPerView: 2.8,
                        spaceBetween: 80,
                    },
                    990: {
                        // width: 990,
                        slidesPerView: 3.4,
                        spaceBetween: 60,
                    },
                }}
                className="portfolio_slider"
            >
                {sliderImages.map((item, index) => (
                    <SwiperSlide className="portfolio_slider_item" key={index}>
                        <div className="portfolio_slider_item_wrapper">
                            <img src={item.sliderImg} alt="Wing coders Portfolio" />
                        </div>
                    </SwiperSlide>
                ))}
                <div className="pagination"></div>
            </Swiper>
        </React.Fragment>
    );
};

export default Portfolio;
