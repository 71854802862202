import React from "react";
import { NavLink } from "react-router-dom";

const DropdownNav = ({ dropdown, dropdowntwo, handleCloseMenu, handleCloseMenuB }) => {
    return (
        <div className={dropdown || dropdowntwo ? "dropdown_nav active" : "dropdown_nav "}>
            {/* // <div className="dropdown_item" key={index}> */}
            {/* <NavLink to="/industries/health-tech">{item.drop}</NavLink>
            </div>
            <div className="dropdown_item">
                <NavLink to="/industries/ecommerce">Ecommerce</NavLink>
            </div>
            <div className="dropdown_item">
                <NavLink to="/industries/education">Education</NavLink>
            </div>
            <div className="dropdown_item">
                <NavLink to="/industries/food">Food</NavLink>
            </div> */}
            {dropNav.map((item, index) => (
                <div className="dropdown_item" key={index}>
                    <NavLink to={item.dropLink} onClick={handleCloseMenu || handleCloseMenuB}>
                        {item.dropTitle}
                    </NavLink>
                </div>
            ))}
        </div>
    );
};
const dropNav = [
    {
        id: 1,
        dropTitle: "Health Technology",
        dropLink: "/industries/health-tech",
    },
    {
        id: 2,
        dropTitle: "Ecommerce",
        dropLink: "/industries/ecommerce",
    },
    {
        id: 3,
        dropTitle: "Education",
        dropLink: "/industries/education",
    },
    {
        id: 4,
        dropTitle: "Food",
        dropLink: "/industries/food",
    },
    {
        id: 5,
        dropTitle: "Fintech",
        dropLink: "/industries/fintech",
    },
    {
        id: 6,
        dropTitle: "Real Estate",
        dropLink: "/industries/realestate",
    },
    {
        id: 7,
        dropTitle: "Communication, Media & Entertainment",
        dropLink: "/industries/communication-media-entertainment",
    },
    {
        id: 8,
        dropTitle: "Transport & Logistics",
        dropLink: "/industries/trasnport-logistics",
    },
];

export default DropdownNav;
