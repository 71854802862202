import React, { useState, useEffect, useRef } from "react";
import Navbar from "../components/Navbar";
import Preloader from "../components/Preloader";
import SplitType from "split-type";
import { gsap, Power4 } from "gsap/dist/gsap";
// import Button from "../components/Button";
import { Link } from "react-router-dom";
import heroVideo from "../videos/hero_video.mp4";
// import heroVideo2 from "../videos/hero_video_2.mp4";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import HomeService from "../components/HomeService";
import HomeCounter from "./../components/HomeCounter";
import HomeIndustries from "../components/HomeIndustries";
import HomeClients from "../components/HomeClients";
import HomeReviews from "../components/HomeReviews";
import Footer from "../components/Footer";
// import { HiArrowLongRight, HiArrowLongLeft } from "react-icons/hi2";
// import { FloatBlueIcon, FloatRedIcon } from "./../icons/HomeIcons";
// import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import HomeFeatures from "../components/HomeFeatures";
// import { WingCodersMonogram } from "../components/Logo";
import { IntroCircleIcons } from "./../icons/HeaderIcons";
import { Seo } from "../components/Seo";
import seoImg from "../images/seo.jpg";
import HomePartners from "../components/HomePartners";
import useWindowSize from "./../components/UseWindowSize";
import HomeServiceMobile from "../components/HomeServiceMobile";

const Home = () => {
    const lineOne = useRef(null);
    const lineTwo = useRef(null);
    const lineThree = useRef(null);
    const buttonRef = useRef(null);
    const heroBannerVideo = useRef([]);
    const introCircle = useRef(null);
    const sliderRef = useRef(null);
    const navRef = useRef([]);
    const [lineOneChars, setLineOneChars] = useState([]);
    const [lineTwoChars, setLineTwoChars] = useState([]);
    const [lineThreeChars, setLineThreeChars] = useState([]);
    const [preloaded, setPreloaded] = useState(false);
    const { width } = useWindowSize();
    useEffect(() => {
        if (lineOne.current == null || lineTwo.current == null || navRef.current == null || buttonRef.current == null) return;
        const oneChars = new SplitType(lineOne.current).chars;
        setLineOneChars(oneChars);
        const twoChars = new SplitType(lineTwo.current).chars;
        setLineTwoChars(twoChars);
        const threeChars = new SplitType(lineThree.current).chars;
        setLineThreeChars(threeChars);
        gsap.set(oneChars, { y: -100, autoAlpha: 1 });
        gsap.set(twoChars, { y: 100, autoAlpha: 1 });
        gsap.set(threeChars, { autoAlpha: 0 });
        gsap.set(navRef.current[0], { autoAlpha: 0, yPercent: 0 });
        gsap.set(buttonRef.current, { y: 100, autoAlpha: 0 });
    }, [lineOne, lineTwo, navRef]);

    function handleOnPreloaderComplete() {
        gsap.to(lineOneChars, {
            duration: 1,
            stagger: 0.01,
            y: 0,
            autoAlpha: 1,
            ease: Power4.easeInOut,
        });
        gsap.to(lineTwoChars, {
            duration: 1,
            stagger: 0.01,
            autoAlpha: 1,
            y: 0,
            ease: Power4.easeInOut,
        });
        gsap.to(lineThreeChars, {
            duration: 1,
            stagger: 0.01,
            delay: 0.1,
            autoAlpha: 1,
            ease: Power4.easeInOut,
        });
        gsap.to(navRef.current[0], {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1,
            stagger: 0.01,
            ease: Power4.easeInOut,
        });
        gsap.to(buttonRef.current, {
            autoAlpha: 1,
            duration: 1,
            delay: 0.1,
            y: 0,
            ease: Power4.easeInOut,
        });

        // setTimeout((e) => {
        //     e.preventDefault();
        //     sliderRef.current.swiper.autoplay.start();
        // }, 5000);
        // setTimeout(() => {
        //     heroBannerVideo.current?.play();

        //     console.log("video");
        // }, 500);
    }
    useEffect(() => {
        if (preloaded) handleOnPreloaderComplete();
    }, [preloaded]);
    useEffect(() => {
        if (introCircle.current == null) return;
        const circleTween = gsap.timeline({
            scrollTrigger: {
                trigger: document.querySelector(".home_intro_section"),
                start: "top 50%",
                toggleActions: "play none none stop",
            },
        });
        circleTween.fromTo(
            introCircle.current,
            {
                scale: 0.9,
            },
            { scale: 1, duration: 1, ease: Power4.easeInOut }
        );
    });
    return (
        <>
            <Seo
                title="Wing Coders"
                meta={[
                    {
                        property: "og:url",
                        content: "https://wingcoders.com/",
                    },
                    {
                        property: "og:type",
                        content: "webiste",
                    },
                    {
                        property: "og:title",
                        content: "Wing Coders",
                    },
                    {
                        property: "og:description",
                        content:
                            "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups.",
                    },
                    {
                        property: "og:image",
                        content: seoImg,
                    },
                    {
                        property: "twitter:card",
                        content: "summary_large_image",
                    },
                    {
                        property: "twitter:title",
                        content: "Wing Coders",
                    },
                    {
                        property: "twitter:description",
                        content:
                            "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups.",
                    },
                    {
                        property: "twitter:image",
                        content: seoImg,
                    },
                ]}
            />
            <Preloader onComplete={() => setPreloaded(true)} />
            <Navbar navRef={navRef} />
            <section className="hero_section">
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
                    effect="fade"
                    // loop={true}
                    spaceBetween={0}
                    slidesPerView={1}
                    speed={2000}
                    autoplay={{ delay: 8000 }}
                    navigation={{
                        nextEl: ".slider_nav .slider_nav_right",
                        prevEl: ".slider_nav .slider_nav_left",
                    }}
                    // scrollbar={{ draggable: true }}
                    onSlideChange={() => {
                        // setTimeout(() => {
                        //     heroBannerVideo.current?.play();
                        // }, 100);
                        console.log("slide");
                        gsap.from(".hero_title_wrapper", {
                            autoAlpha: 0,
                            y: 200,
                            delay: 1,
                            duration: 1,
                            stagger: 0.1,
                            ease: Power4.easeInOut,
                        });
                    }}
                    className="hero_slider"
                    ref={sliderRef}
                >
                    <SwiperSlide className="hero_slider_item">
                        <div className="hero_slider_item_wrapper">
                            <div className="hero_slider_item_video">
                                <video
                                    src={heroVideo}
                                    autoPlay={true}
                                    muted
                                    loop
                                    controls={false}
                                    preload="auto"
                                    className="hero_banner_video"
                                    ref={(el) => (heroBannerVideo.current[0] = el)}
                                ></video>
                            </div>
                            <div className="container">
                                <div className="hero_title_wrapper">
                                    <h1 className="hero_title display-2 mt-5 mt-xl-0 mb-0" ref={lineOne}>
                                        For the new <span className="text-primary">wings</span>
                                    </h1>
                                    <h1 className="hero_title display-2 mb-0" ref={lineTwo}>
                                        chasing <span className="text-primary">dreams</span>
                                    </h1>
                                    <h6 className="display-5 mt-3 mt-xl-5 mb-0" ref={lineThree}>
                                        We provide the sky full of possibilities
                                    </h6>
                                    <div className="hero_link d-inline-flex mt-5" ref={buttonRef}>
                                        <Link className="primary_button primary_button--white" to="/services">
                                            <span>Explore</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* <SwiperSlide className="hero_slider_item">
                        <div className="hero_slider_item_wrapper">
                            <div className="hero_slider_item_video">
                                <video
                                    src={heroVideo2}
                                    autoPlay={true}
                                    muted
                                    loop
                                    preload="auto"
                                    className="hero_banner_video"
                                    ref={(el) => (heroBannerVideo.current[1] = el)}
                                ></video>
                            </div>
                            <div className="container">
                                <div className="hero_title_wrapper">
                                    <h1 className="hero_title display-1 mb-0">Lorem ipsum dolor</h1>
                                    <h1 className="hero_title display-1 mb-0">ipsum dolor</h1>
                                    <h6 className="display-5 mt-5 mb-0">Lorem ipsum dolor sit, amet consectetur adipisicing</h6>
                                    <div className="hero_link d-inline-flex mt-5">
                                        <Link className="primary_button primary_button--white" to="#">
                                            <span>Explore</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide> */}
                    {/* <div className="slider_nav">
                        <button className="slider_nav_left">
                            <HiArrowLongLeft />
                        </button>
                        <button className="slider_nav_right">
                            <HiArrowLongRight />
                        </button>
                    </div> */}
                </Swiper>
                <div className="container">
                    <div id="scroll-down-animation">
                        <span class="mouse">
                            <span class="move"></span>
                        </span>
                        <p>Scroll down</p>
                    </div>
                </div>
            </section>
            {/* <div className="section_gap"></div> */}
            <section className="home_main">
                <div className="container">
                    <div className="home_main_head mb-4">
                        <p className="subtitle subtitle--black">Wing Coders</p>
                        <h2 className="display-3">
                            Who <span className="text-primary">We Are?</span>
                        </h2>
                    </div>
                    <div className="home_main_wrapper">
                        {/* <div className="home_main_wrapper--bg">
                            <IntroCircleIcons />
                        </div> */}

                        <div className="home_main_left">
                            {/* <h2 className="display-3 mb-4">Who We Are?</h2> */}

                            <p className="paragraph mb-4">
                                <span className="text-primary fw-bold">Wing Coders</span> is a group of highly skilled technologists that came together with the philosophy of
                                creating a great technology organisation that would favourably impact the lives of millions.
                            </p>
                            <Link to="/about" className="primary_button  ">
                                Know more about us
                            </Link>
                        </div>
                        <div className="home_main_right">
                            <p className="paragraph">
                                The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global
                                organisations and technology startups. We accelerate growth to your business with Technology, Experience, and Innovation.
                            </p>
                            <Link to="/about" className="primary_button mt-4">
                                Know more about us
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* <div className="section_gap"></div> */}
            <section className="home_intro_section">
                {/* <div className="home_intro_section_icon">
                    <FloatBlueIcon />
                </div>
                <div className="home_intro_section_icon home_intro_section_icon--top">
                    <FloatRedIcon />
                </div> */}
                <div className="container">
                    <div className="home_intro_wrapper">
                        <div className="home_intro_wrapper_bg" ref={introCircle}>
                            {/* <WingCodersMonogram />
                             */}
                            <IntroCircleIcons />
                        </div>
                        <div className="home_intro">
                            <div className="home_intro_left">
                                <div className="home_intro_left_wrapper">
                                    <p className="subtitle subtitle--black">Wing Coders</p>
                                    <h2 className="display-3">
                                        What <span className="text-primary">We Do?</span>
                                    </h2>
                                    <p className="paragraph">
                                        We scale-up your close-knit development team. We launch highly scalable applications to enhance your brands. We upgrade and revamp your
                                        business platforms with emerging technologies.
                                    </p>
                                </div>
                            </div>
                            <div className="home_intro_right">
                                <div className="home_intro_items">
                                    {homeIntroContent.map((item, index) => (
                                        <div className="home_intro_item" key={index}>
                                            <h5 className="display-6 mb-3">{item.title}</h5>
                                            <p className="paragraph">{item.description}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <HomeFeatures />
            {width > 1024 ? <HomeService /> : <HomeServiceMobile />}
            {/* <HomeService /> */}
            <HomeIndustries />
            <div className="home_clients_bg">
                <HomeClients />
                <HomePartners />
            </div>
            <HomeReviews />
            <HomeCounter />
            <Footer />
        </>
    );
};

export default Home;

// const heroSwiperSlider = [
//     {
//         id: 1,
//         titleA: "For the new wings,",
//         titleB: "chasing dreams",
//         desc: "We provide the sky full of possibilities",
//         link: "#",
//         videoSrc: heroVideo,
//     },
//     {
//         id: 2,
//         titleA: "Lorem ipsum dolor",
//         titleB: "amet consectetur",
//         desc: "Lorem ipsum dolor sit, amet consectetur adipisicing",
//         link: "#",
//         videoSrc: heroVideo2,
//     },
// ];

const homeIntroContent = [
    // {
    //     id: 1,
    //     title: "WHO WE ARE?",
    //     description:
    //         "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups. We accelerate growth to your business with Technology, Experience, and Innovation.",
    // },
    {
        id: 1,
        title: "LEVERAGE OUR EXPERTISE",
        description:
            "Our tech talents will love to add more value to your initiatives. Build your dream team with the experienced IT professionals to get your project off the ground quickly and efficiently.",
    },
    {
        id: 2,
        title: "CUSTOM SOFTWARE DEVELOPMENT",
        description: "We focus on creating aesthetic applications to optimise your business by connecting dots from Design to Deployment.",
    },
    {
        id: 3,
        title: "UPGRADE AND REVAMP YOUR PLATFORM ",
        description:
            "In today's fast-paced market, it is essential to regularly update and improve your products to stay ahead of the competition and meet the ever-changing needs of your customers.",
    },
];
