import CustomPortal from "../images/industries/real_estate/portal.png";
import BookingEngine from "../images/industries/real_estate/booking_engine.png";
import CustomerRelation from "../images/industries/real_estate/customer_relation.png";
import PorpertyManage from "../images/industries/real_estate/property_management.png";

export const RealestateData = [
    {
        id: 1,
        solutionIcon: CustomPortal,
        solutionTitle: "Custom Real Estate Portals",
        solutionDesc:
            "These personalised real estate platforms are attracting significant attention from venture capitalists and equity investors. These portals offer comprehensive information, including floor plans, directions, nearby amenities, and other details, providing users with a virtual site visit experience. This enables users to effortlessly select their desired apartments and virtually explore them.",
    },
    {
        id: 2,
        solutionIcon: BookingEngine,
        solutionTitle: "Booking Engines",
        solutionDesc:
            "Our online reservation platform offers customers immediate booking and payment options seamlessly integrated into the system. The entire process, from a potential customer deciding to book a workspace to selecting, viewing, ordering furniture, and completing the payment, is conducted online. This not only decreases the workload for staff and minimises human errors but also enhances overall business efficiency. The system provides customers with various online booking options for greater flexibility.",
    },

    {
        id: 3,
        solutionIcon: CustomerRelation,
        solutionTitle: "Customer Relationship Management ",
        solutionDesc:
            " Our Customer Relationship Management System automates a range of daily tasks, enhancing the productivity of your sales team and encouraging increased property sales. By employing automated communications, this system guarantees that no opportunity to connect with potential customers is overlooked. It serves as the ideal solution for your business, efficiently handling tasks such as follow-ups, project plans, site visits, marketing campaigns, and more.",
    },
    {
        id: 4,
        solutionIcon: PorpertyManage,
        solutionTitle: "Property Management Solutions ",
        solutionDesc:
            "Wing Coders offers property management solutions designed to enhance the efficiency of your real estate operations. Our goal is to streamline your daily tasks, freeing up more time for you to concentrate on your customers. Through our property management solutions, you can automate manual processes, save time, and boost productivity for your organisation. We take pride in delivering top-notch solutions to meet all your property management needs.",
    },
];
