import React, { useEffect, useRef } from "react";
import { homeServiceContentBig } from "../content/HomeServiceContentData";
import NavbarPage from "./../components/NavbarPage";
import PageHeader from "./../components/PageHeader";
import { ServiceHeader } from "./../icons/HeaderIcons";
import { gsap, Power4 } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import Footer from "../components/Footer";
import ServiceTab from "../components/ServiceTab";
import { Link } from "react-router-dom";
import TaglineMobile from "../components/TaglineMobile";
import { Seo } from "../components/Seo";
import seoImg from "../images/seo.jpg";
const Services = () => {
    const serviceIconRef = useRef([]);
    const serviceIconBgRef = useRef([]);
    const serviceSectionRef = useRef([]);
    serviceSectionRef.current = [];
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        // if (serviceIconRef.current || serviceIconBgRef.current || serviceSectionRef.current === null) return;
        serviceSectionRef.current.map((section, index) => {
            const tween = gsap.timeline({
                // onEnter: () => tween.play(),
                // onComplete: () => tween.pause,
                scrollTrigger: {
                    id: `section ${index + 1}`,
                    trigger: section,
                    start: "top 59%",
                    // end: "bottom 100%",
                    // markers: true,
                    toggleActions: "play none none none",
                },
            });
            tween
                .from(serviceIconRef.current[index], {
                    y: -60,
                    duration: 0.8,
                    ease: Power4.easeInOut,
                })
                .from(serviceIconBgRef.current[index], {
                    y: 60,
                    duration: 0.8,
                    delay: -0.8,
                    ease: Power4.easeInOut,
                });

            return () => {
                tween.pause();
                // tween2.kill();
            };
        });
        console.log("123");
    }, []);
    const addToRefs = (el) => {
        if (el && !serviceSectionRef.current.includes(el)) {
            serviceSectionRef.current.push(el);
        }
    };
    return (
        <>
            <Seo
                title="Services | Wing Coders"
                meta={[
                    {
                        property: "og:url",
                        content: "https://wingcoders.com/services",
                    },
                    {
                        property: "og:type",
                        content: "webiste",
                    },
                    {
                        property: "og:title",
                        content: "Services | Wing Coders",
                    },
                    {
                        property: "og:description",
                        content:
                            "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups.",
                    },
                    {
                        property: "og:image",
                        content: seoImg,
                    },
                    {
                        property: "twitter:card",
                        content: "summary_large_image",
                    },
                    {
                        property: "twitter:title",
                        content: "Services | Wing Coders",
                    },
                    {
                        property: "twitter:description",
                        content:
                            "The intuitive and preeminent Web and Mobile application development company that provides end-to-end IT services and solutions for global organisations and technology startups.",
                    },
                    {
                        property: "twitter:image",
                        content: seoImg,
                    },
                ]}
            />
            <NavbarPage />
            <PageHeader title="Services" headerIcon={<ServiceHeader />} tagline={<>We transform your business environment with Emerging Technologies</>} />
            <TaglineMobile taglinemobile={<>We transform your business environment with Emerging Technologies</>} />
            <div className="section_gap "></div>
            <ServiceTab />
            {homeServiceContentBig.map((item, index) => (
                <section className="service_section" ref={addToRefs} key={index}>
                    <div className="service_row_col section_vh--service">
                        <div className="container">
                            <div className={`${item.rowColClass}`}>
                                <div className="service_col_left">
                                    <div className="service_col_left_wrapper">
                                        <div className="service_col_left_content">
                                            <h6 className="subtitle">Services</h6>
                                            <h3 className="display-3">{item.title}</h3>
                                            <p className="paragraph">{item.serviceDesc}</p>
                                        </div>
                                        <ServiceTools toolsIcons={item.toolsIcons} />
                                        <Link className="mt-4 primary_button" to="/contact">
                                            {item.buttonText}
                                        </Link>
                                    </div>
                                </div>
                                <div className="service_col_right">
                                    <div className="service_col_right_icon">
                                        <img src={item.iconVectorBig} alt={item.title} ref={(el) => (serviceIconRef.current[index] = el)} />
                                        <div className="service_col_right_bg" ref={(el) => (serviceIconBgRef.current[index] = el)}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ))}
            <div className="section_gap"></div>
            <Footer />
        </>
    );
};

export default Services;

const ServiceTools = ({ toolsIcons }) => {
    return (
        <>
            <div className="service_col_left_icon_section">
                {toolsIcons.map((item, index) => {
                    return (
                        <div className="service_col_left_icons" key={index}>
                            <div className="service_col_left_icon">{item.iconVector}</div>
                            <h6 className="display-6">{item.iconDesc}</h6>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
