import React, { useEffect } from "react";
import { homeServiceContent } from "../content/HomeServiceContentData";

const HomeServiceMobile = () => {
    useEffect(() => {
        require("bootstrap/dist/js/bootstrap.bundle.min.js");
    }, []);
    return (
        <section className="section_padding">
            <div className="container">
                <div className="home_service_intro ">
                    <h6 className="subtitle subtitle--black">Wing coders</h6>
                    <h2 className="display-3">
                        <span className="text-primary">Services</span> We Provide
                    </h2>
                </div>
                <div className="accordion" id="home_service_mobile">
                    {homeServiceContent.map((item, index) => (
                        <div className="accordion-item" key={index}>
                            <h2 className="accordion-header " id={`heading_${item.id}`}>
                                <button
                                    className={`home_service_item ${item.class} `}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse_${item.id}`}
                                    aria-expanded="true"
                                    aria-controls={`collapse_${item.id}`}
                                >
                                    <div className="home_service_item_wrapper">
                                        <div className="home_service_item_icon">{item.iconVector}</div>
                                        <div className="home_service_item_title">{item.title}</div>
                                        <div className="home_service_item_index">0{item.id}</div>
                                    </div>
                                </button>
                            </h2>
                            <div id={`collapse_${item.id}`} className="accordion-collapse collapse " aria-labelledby={`heading_${item.id}`} data-bs-parent="#home_service_mobile">
                                <div className="accordion-body">
                                    <div className="home_service_content_wrapper">
                                        <div className="home_service_content">
                                            <h3 className="display-4">{item.title}</h3>
                                            <p className="paragraph">{item.serviceDesc}</p>
                                        </div>
                                        <div className="">
                                            <h5 className="display-6 mb-3">{item.toolsDesc}</h5>
                                            <ContentTabMobile toolsIcons={item.toolsIcons} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default HomeServiceMobile;

const ContentTabMobile = ({ toolsIcons }) => {
    return (
        <>
            <div className="home_service_col">
                {toolsIcons.map((item, index) => {
                    return (
                        <div className="home_service_icons" key={index}>
                            <div className="home_service_icon">{item.iconVector}</div>
                            <h6 className="display-6">{item.iconDesc}</h6>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
