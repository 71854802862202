import React, { useEffect, useRef } from "react";
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { Link } from "react-router-dom";

const Parallax = ({ image }) => {
    const parallaxImageref = useRef(null);

    gsap.registerPlugin(ScrollTrigger);
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        if (parallaxImageref.current == null) return;
        const tween = gsap.to(parallaxImageref.current, {
            backgroundPosition: `50% ${-window.innerHeight / 2}px`,
            ease: "none",
            scrollTrigger: {
                trigger: document.querySelector(".parallax"),
                scrub: true,
                // markers: true,
            },
        });

        return () => {
            tween.kill();
        };
    }, []);

    return (
        <section className="parallax">
            <div
                ref={parallaxImageref}
                className="parallax_image"
                style={{
                    backgroundImage: `url(${image})`,
                }}
            >
                <div className="parallax_content">
                    <div className="parallax_content_wrapper">
                        <h4 className="display-4">Hire an expert to your project</h4>
                        <Link to="/contact" className="primary_button primary_button--white">
                            Contact
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Parallax;
