import React from "react";
import { EduGames, Elearning, LmsConsulting, VirtualClassroom } from "./../icons/IndustriesIcons";
export const EducationSolutionsData = [
    {
        id: 1,
        solutionIcon: <LmsConsulting />,
        solutionTitle: "LMS Consulting",
        solutionDesc:
            "We design and develop LMS Applications to help organisations and institutions implement and optimise their learning management systems that provide a range of services, including needs assessment, LMS selection and implementation, and integration and customization.",
    },
    {
        id: 2,
        solutionIcon: <Elearning />,
        solutionTitle: "ELearning Applications",
        solutionDesc:
            "We develop e-learning platforms that provide students with access to a wide range of educational resources, including online courses, video lectures, and interactive quizzes",
    },
    {
        id: 3,
        solutionIcon: <VirtualClassroom />,
        solutionTitle: "Virtual Classroom",
        solutionDesc:
            " We build applications that enable online learning and teaching in real-time video conferencing. It provides a virtual environment where students and teachers can interact, participate in chats and discussions, recordings, document sharings, virtual examinations and complete assignments together.",
    },
    {
        id: 4,
        solutionIcon: <EduGames />,
        solutionTitle: "Educational Games ",
        solutionDesc:
            "We utilise gamification techniques to make learning more engaging and enjoyable. Our education applications use game-like elements such as points, rewards, and challenges to motivate students and keep them engaged.",
    },
];
