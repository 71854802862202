import React, { useState } from "react";
import { Tab } from "@headlessui/react";
// import { gsap } from "gsap/dist/gsap";
import ServiceTabIcon from "../images/services/tab_icon_1.png";
import ServiceTabIconB from "../images/services/tab_icon_2.png";
import ServiceTabIconC from "../images/services/tab_icon_3.png";
import { ManagedService, ProductDesign, ProductDeveoplment, ProductStrategy, ManagedTeam, ItStaffing } from "./../icons/ServiceTabIcons";
const ServiceTab = () => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    // const TabServiceRef = useRef([]);
    return (
        <>
            <div className="container">
                <div className="service_tab_wrapper">
                    <Tab.Group active={activeTabIndex} onChange={setActiveTabIndex}>
                        <div className="service_tab">
                            <Tab.List className="service_tab_items">
                                {serviceTabContent.map((item, index) => (
                                    <Tab className="service_tab_items_wrap" key={index}>
                                        {({ selected }) => <div className={selected ? "service_tab_item active" : "service_tab_item"}>{item.title}</div>}
                                    </Tab>
                                ))}
                            </Tab.List>

                            <div className="service_tab_bottom">
                                <Tab.Panels>
                                    {serviceTabContent.map((item, index) => (
                                        <Tab.Panel className="service_tab_content_wrapper" key={index}>
                                            <div className="service_tab_content">
                                                <ServiceRightTab tabIcon={item.tabIcon} />
                                                <div className="service_tab_content_right">
                                                    <img src={item.iconBg} alt="Wing Coders Service model" />
                                                </div>
                                            </div>
                                        </Tab.Panel>
                                    ))}
                                </Tab.Panels>
                            </div>
                        </div>
                    </Tab.Group>
                </div>
            </div>
        </>
    );
};

const ServiceRightTab = ({ tabIcon }) => {
    return (
        <>
            <div className="service_tab_content_left_wrapper">
                {tabIcon.map((item, index) => (
                    <div className="service_tab_content_left" key={index}>
                        <div className="service_tab_content_left_icon_wrapper">
                            <div className="service_tab_content_left_icon">{item.icon}</div>
                        </div>
                        <div className="service_tab_content_left_para">
                            <h6 className="display-6 mb-1">{item.iconTitle}</h6>
                            <p className="paragraph">{item.para}</p>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

const serviceTabContent = [
    {
        id: 1,
        title: "Build a software product",
        tabIcon: [
            {
                icon: <ProductDeveoplment />,
                iconTitle: "Product development ",
                para: "We take care of the entire development cycle of your product, from conceptualization to deployment.",
            },
            {
                icon: <ProductDesign />,
                iconTitle: "Product design",
                para: "We have UX/UI design experts to create powerful user interfaces. Platforms that offer the best experience.",
            },
            {
                icon: <ProductStrategy />,
                iconTitle: "Product strategy",
                para: "We shape the vision you have for your product and create an effective roadmap to successfully develop and promote it.",
            },
        ],
        iconBg: ServiceTabIcon,
    },
    {
        id: 2,
        title: "Maintain my software",
        tabIcon: [
            {
                icon: <ManagedService />,
                iconTitle: "Managed services",
                para: "While you focus on the core aspects of your business, we handle the routine but important services and operations.",
            },
            {
                icon: <ManagedTeam />,
                iconTitle: "Managed Teams",
                para: "We provide you with a dedicated team that will handle the development of your project. You will always be in control.",
            },
        ],
        iconBg: ServiceTabIconB,
    },
    {
        id: 3,
        title: "Add an expert to my project",
        tabIcon: [
            {
                icon: <ItStaffing />,
                iconTitle: "IT Staffing",
                para: "Hire on-demand engineers and designers to streamline and empower your software development.",
            },
            {
                icon: <ManagedTeam />,
                iconTitle: "Managed Teams",
                para: "We provide you with a dedicated team that will handle the development of your project. You will always be in control.",
            },
        ],
        iconBg: ServiceTabIconC,
    },
];

export default ServiceTab;
