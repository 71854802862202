import React, { useRef, useEffect } from "react";
import { gsap, Power2 } from "gsap/dist/gsap";
import { RiDoubleQuotesL } from "react-icons/ri";

const PageHeader = ({ title, headerIcon, tagline = " ", hide_tagline = "" }) => {
    const pageheaderRef = useRef([]);
    useEffect(() => {
        if (pageheaderRef.current == null) return;
        gsap.from(pageheaderRef.current[0], {
            x: -100,
            autoAlpha: 0,
            duration: 0.5,
            ease: Power2.easeIn,
        });
        gsap.from(pageheaderRef.current[1], {
            y: 100,
            duration: 1,
            ease: Power2.easeIn,
        });
        gsap.from(pageheaderRef.current[2], {
            autoAlpha: 0,
            delay: 1,
            duration: 0.5,
            ease: Power2.easeIn,
        });
    });
    return (
        <>
            <div className={`page_header ${hide_tagline}`}>
                <div className="page_header_bg">
                    <div
                        className="page_header_bg_icon"
                        ref={(el) => {
                            pageheaderRef.current[0] = el;
                        }}
                    >
                        {headerIcon}
                    </div>
                </div>
                <div className="container">
                    <div className="page_header_wrapper">
                        <div className="page_header_title">
                            <h1
                                className="display-2"
                                ref={(el) => {
                                    pageheaderRef.current[1] = el;
                                }}
                            >
                                {title}
                            </h1>
                        </div>
                        <div
                            className="page_header_quote"
                            ref={(el) => {
                                pageheaderRef.current[2] = el;
                            }}
                        >
                            <span className="page_header_quote_line">
                                <RiDoubleQuotesL />
                            </span>
                            <h6 className="display-5">{tagline}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageHeader;
