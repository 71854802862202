import { MdDeveloperMode } from "react-icons/md";
import {
    Android,
    Angular,
    Microsoftazure,
    Ionic,
    Java,
    ReactJS,
    Reactnative,
    Sketch,
    Googlecloud,
    Blender,
    UiUx,
    MobileApp,
    DigitalMarketing,
    CloudComputing,
    GameDev,
    Webdev,
    DataAnalytics,
} from "../icons/HomeServiceIcons";
import { Figma, Adobexd, Ios, Flutter, Nodejs, Phyton, Docker } from "./../icons/HomeServiceIcons";
import { SiUnrealengine } from "react-icons/si";
import { FaUnity } from "react-icons/fa";

// import {
//     GameDevBig,
//     CloudComputingBig,
//     MobileAppDevBig,
//     UiUxBig,
//     WebAppDevBig,
//     DigitalMarketingBig,
// } from "../icons/ServiceBigIcons";
import GameDevBig from "../images/services/game_dev.png";
import DataAnlayticsBig from "../images/services/data-analytics.png";
import CloudComputingBig from "../images/services/cloud_computing.png";
import MobileAppDevBig from "../images/services/mobile_app_dev.png";
import WebAppDevBig from "../images/services/web_app_dev.png";
import DigitalMarketingBig from "../images/services/digital_marketing_service.png";
import UiUxBig from "../images/services/ui_ux_design.png";
import { ContentWriting, Cro, Emailmarketing, SeoIntegeration, Smo } from "../icons/HomeIcons";
import { SiDotnet } from "react-icons/si";
import { color } from "framer-motion";

export const homeServiceContent = [
    {
        id: 1,
        title: "UI/UX Design",
        iconVector: <UiUx />,
        // iconVectorBig: UiUxg />,
        // iconVectorBig: UiUxBig,
        iconVectorBig: UiUxBig,
        class: "red",
        rowColClass: "service_col service_col--reverse",
        serviceDesc: "Enhance your business with slick and intuitive mobile designs tailored for demanding mobile users.",
        toolsDesc: "Tools We Use",
        toolsIcons: [
            {
                iconId: 1,
                iconVector: <Sketch />,
                iconDesc: "Sketch",
            },
            {
                iconId: 2,
                iconVector: <Figma />,
                iconDesc: "Figma",
            },
            {
                iconId: 3,
                iconVector: <Adobexd />,
                iconDesc: "Adobe XD",
            },
        ],
    },
    {
        id: 2,
        title: "Mobile App Development",
        // iconVectorBig: <MobileAppDevBig />,
        // iconVectorBig: MobileAppDevBig ,
        iconVectorBig: MobileAppDevBig,
        class: "yellow",
        rowColClass: "service_col",
        iconVector: <MobileApp />,
        serviceDesc: "Our mobile expertise leverage cutting-edge technologies to create user-centric and versatile apps.",
        toolsDesc: "Tools We Use",
        toolsIcons: [
            {
                iconId: 1,
                iconVector: <Android />,
                iconDesc: "Android",
            },
            {
                iconId: 2,
                iconVector: <Ios />,
                iconDesc: "IOS",
            },
            {
                iconId: 3,
                iconVector: <Reactnative />,
                iconDesc: "React Native",
            },
            {
                iconId: 4,
                iconVector: <Flutter />,
                iconDesc: "Flutter",
            },
            {
                iconId: 5,
                iconVector: <Ionic />,
                iconDesc: "Ionic",
            },
        ],
    },
    {
        id: 3,
        title: "Web App Development",
        iconVector: <Webdev />,
        // iconVectorBig: <WebAppDevBig />,
        iconVectorBig: WebAppDevBig,
        class: "green",
        rowColClass: "service_col service_col--reverse",
        serviceDesc: "We build a high performing marketing tool in the form of a website that tells a visual story about your brand which leads to the success of every app",
        toolsDesc: "Tools We Use",
        toolsIcons: [
            {
                iconId: 1,
                iconVector: <Ionic />,
                iconDesc: "Ionic",
            },
            {
                iconId: 2,
                iconVector: <Nodejs />,
                iconDesc: "Node JS",
            },
            {
                iconId: 3,
                iconVector: <Java />,
                iconDesc: "Java",
            },
            {
                iconId: 4,
                iconVector: <Phyton />,
                iconDesc: "Phython",
            },
            {
                iconId: 5,
                iconVector: <SiDotnet style={{ fill: "white !important" }} />,
                iconDesc: ".NET",
            },
            {
                iconId: 6,
                iconVector: <ReactJS />,
                iconDesc: "React JS",
            },
            {
                iconId: 7,
                iconVector: <Angular />,
                iconDesc: "Angular",
            },
        ],
    },
    {
        id: 4,
        title: "Data & Analytics",
        iconVector: <DataAnalytics />,
        // iconVectorBig: <GameDevBig />,
        iconVectorBig: DataAnlayticsBig,
        class: "lightgreen",
        rowColClass: "service_col service_col--reverse service-col--icon-hide",

        serviceDesc: "From strategic decision support to predictive analytics, we specialise in optimising operations, enhancing customer experiences, and driving revenue growth.",
        toolsDesc: "Tools We Use",
        toolsIcons: [
            {
                iconId: 1,
                // iconVector: <Unity />,
                iconDesc: "Data Migration Services",
            },
            {
                iconId: 2,
                // iconVector: <Unity />,
                iconDesc: "Data Engineering Services",
            },
            {
                iconId: 3,
                // iconVector: <Unity />,
                iconDesc: "Data Governance Services",
            },
            {
                iconId: 4,
                // iconVector: <Unity />,
                iconDesc: "Data Quality Services",
            },
            {
                iconId: 5,
                // iconVector: <Unity />,
                iconDesc: "Data Management Services",
            },
            {
                iconId: 6,
                // iconVector: <Unity />,
                iconDesc: "Data Security Services",
            },
        ],
    },
    {
        id: 5,
        title: "Digital Marketing",
        iconVector: <DigitalMarketing />,
        // iconVectorBig: <DigitalMarketingBig />,
        iconVectorBig: DigitalMarketingBig,
        class: "blue",
        rowColClass: "service_col",
        serviceDesc:
            "We find your audience or help your audience find you. One of the fool-proof ways to reach your target audience and increase your brand awareness is by digital marketing.",
        toolsDesc: "Services we provide",
        toolsIcons: [
            {
                iconId: 1,
                iconVector: <Emailmarketing />,
                iconDesc: "Email Marketing",
            },
            {
                iconId: 2,
                iconVector: <SeoIntegeration />,
                iconDesc: "SEO Integration",
            },
            {
                iconId: 3,
                iconVector: <Cro />,
                iconDesc: "CRO",
            },
            {
                iconId: 4,
                iconVector: <Smo />,
                iconDesc: "SMO",
            },
            {
                iconId: 5,
                iconVector: <ContentWriting />,
                iconDesc: "Content Writing",
            },
        ],
    },
    {
        id: 6,
        title: "Cloud Computing",
        iconVector: <CloudComputing />,
        // iconVectorBig: <CloudComputingBig />,
        iconVectorBig: CloudComputingBig,
        class: "pink",
        rowColClass: "service_col service_col--reverse",
        serviceDesc:
            "We help you save your capital expenses by opting for cloud computing services. Some of the key benefits of choosing our cloud computing solutions are Affordable, Data Security, Data Storage, and Better Mobility.",
        toolsDesc: "Tools We Use",
        toolsIcons: [
            {
                iconId: 1,
                iconVector: <Microsoftazure />,
                iconDesc: "Microsoft Azure",
            },
            {
                iconId: 2,
                iconVector: <Googlecloud />,
                iconDesc: "Google Cloud",
            },
            {
                iconId: 3,
                iconVector: <Docker />,
                iconDesc: "Docker",
            },
        ],
    },
    {
        id: 7,
        title: "Game Development",
        iconVector: <GameDev />,
        // iconVectorBig: <GameDevBig />,
        iconVectorBig: GameDevBig,
        class: "lightpink",
        rowColClass: "service_col",

        serviceDesc: "We transform games from a unique concept to a playable reality through appealing designs, graphics and animation that can engage a lot of users.",
        toolsDesc: "Tools We Use",
        toolsIcons: [
            {
                iconId: 1,
                iconVector: <FaUnity style={{ fill: "white" }} />,
                iconDesc: "Unity",
            },
            {
                iconId: 2,
                iconVector: <SiUnrealengine style={{ fill: "white" }} />,
                iconDesc: "Unreal Engine",
            },
            {
                iconId: 3,
                iconVector: <Blender />,
                iconDesc: "Blender",
            },
        ],
    },
];

export const homeServiceContentBig = [
    {
        id: 1,
        title: "UI/UX Design",
        iconVector: <UiUx />,
        // iconVectorBig: UiUxg />,
        // iconVectorBig: UiUxBig,
        iconVectorBig: UiUxBig,
        class: "red",
        rowColClass: "service_col service_col--reverse",
        serviceDesc: "Enhance your business with slick and intuitive mobile designs tailored for demanding mobile users.",
        toolsDesc: "Tools We Use",
        buttonText: "Leverage our developers now",
        toolsIcons: [
            {
                iconId: 1,
                iconVector: <Sketch />,
                iconDesc: "Sketch",
            },
            {
                iconId: 2,
                iconVector: <Figma />,
                iconDesc: "Figma",
            },
            {
                iconId: 3,
                iconVector: <Adobexd />,
                iconDesc: "Adobe XD",
            },
        ],
    },
    {
        id: 2,
        title: "Mobile App Development",
        // iconVectorBig: <MobileAppDevBig />,
        // iconVectorBig: MobileAppDevBig ,
        iconVectorBig: MobileAppDevBig,
        class: "yellow",
        rowColClass: "service_col",
        iconVector: <MobileApp />,
        serviceDesc: "Our mobile expertise leverage cutting-edge technologies to create user-centric and versatile apps.",
        toolsDesc: "Tools We Use",
        buttonText: "Leverage our developers now",
        toolsIcons: [
            {
                iconId: 1,
                iconVector: <Android />,
                iconDesc: "Android",
            },
            {
                iconId: 2,
                iconVector: <Ios />,
                iconDesc: "IOS",
            },
            {
                iconId: 3,
                iconVector: <Reactnative />,
                iconDesc: "React Native",
            },
            {
                iconId: 4,
                iconVector: <Flutter />,
                iconDesc: "Flutter",
            },
            {
                iconId: 5,
                iconVector: <Ionic />,
                iconDesc: "Ionic",
            },
        ],
    },
    {
        id: 3,
        title: "Web App Development",
        iconVector: <Webdev />,
        // iconVectorBig: <WebAppDevBig />,
        iconVectorBig: WebAppDevBig,
        class: "green",
        rowColClass: "service_col service_col--reverse",
        serviceDesc: "We build a high performing marketing tool in the form of a website that tells a visual story about your brand which leads to the success of every app",
        toolsDesc: "Tools We Use",
        buttonText: "Leverage our developers now",
        toolsIcons: [
            {
                iconId: 1,
                iconVector: <Ionic />,
                iconDesc: "Ionic",
            },
            {
                iconId: 2,
                iconVector: <Nodejs />,
                iconDesc: "Node JS",
            },
            {
                iconId: 3,
                iconVector: <Java />,
                iconDesc: "Java",
            },
            {
                iconId: 4,
                iconVector: <Phyton />,
                iconDesc: "Phython",
            },
            {
                iconId: 5,
                iconVector: <SiDotnet style={{ fill: "white" }} />,
                iconDesc: ".NET",
            },
            {
                iconId: 6,
                iconVector: <ReactJS />,
                iconDesc: "React JS",
            },
            {
                iconId: 7,
                iconVector: <Angular />,
                iconDesc: "Angular",
            },
        ],
    },
    {
        id: 4,
        title: "Data & Analytics",
        iconVector: <DataAnalytics />,
        // iconVectorBig: <GameDevBig />,
        iconVectorBig: DataAnlayticsBig,
        buttonText: "Leverage our developers now",
        class: "lightpink",
        rowColClass: "service_col  service-col--icon-hide",

        serviceDesc:
            "From strategic decision support to predictive analytics, we specialise in optimising operations, enhancing customer experiences, and driving revenue growth. Let us transform your data into a strategic asset, guiding you towards success in a data-driven world.",
        toolsDesc: "Tools We Use",
        toolsIcons: [
            {
                iconId: 1,
                // iconVector: <Unity />,
                iconDesc: "Data Migration Services",
            },
            {
                iconId: 2,
                // iconVector: <Unity />,
                iconDesc: "Data Engineering Services",
            },
            {
                iconId: 3,
                // iconVector: <Unity />,
                iconDesc: "Data Governance Services",
            },
            {
                iconId: 4,
                // iconVector: <Unity />,
                iconDesc: "Data Quality Services",
            },
            {
                iconId: 5,
                // iconVector: <Unity />,
                iconDesc: "Data Management Services",
            },
            {
                iconId: 6,
                // iconVector: <Unity />,
                iconDesc: "Data Security Services",
            },
        ],
    },
    {
        id: 5,
        title: "Digital Marketing",
        iconVector: <DigitalMarketing />,
        // iconVectorBig: <DigitalMarketingBig />,
        iconVectorBig: DigitalMarketingBig,
        class: "blue",
        rowColClass: "service_col service_col--reverse",
        serviceDesc:
            "We find your audience or help your audience find you. One of the fool-proof ways to reach your target audience and increase your brand awareness is by digital marketing. As a reputed organisation in this domain, we ensure to meet our client’s satisfactions with our affecting marketing strategies that lets your clients come to you.",
        toolsDesc: "Services we provide",
        buttonText: "Leverage our experts now",
        toolsIcons: [
            {
                iconId: 1,
                iconVector: <Emailmarketing />,
                iconDesc: "Email Marketing",
            },
            {
                iconId: 2,
                iconVector: <SeoIntegeration />,
                iconDesc: "SEO Integration",
            },
            {
                iconId: 3,
                iconVector: <Cro />,
                iconDesc: "CRO",
            },
            {
                iconId: 4,
                iconVector: <Smo />,
                iconDesc: "SMO",
            },
            {
                iconId: 5,
                iconVector: <ContentWriting />,
                iconDesc: "Content Writing",
            },
        ],
    },
    {
        id: 6,
        title: "Cloud Computing",
        iconVector: <CloudComputing />,
        // iconVectorBig: <CloudComputingBig />,
        iconVectorBig: CloudComputingBig,
        class: "pink",
        rowColClass: "service_col ",
        serviceDesc:
            "We help you save your capital expenses by opting for cloud computing services. Some of the key benefits of choosing our cloud computing solutions are Affordable, Data Security, Data Storage, and Better Mobility.",
        toolsDesc: "Tools We Use",
        buttonText: "Leverage our developers now",
        toolsIcons: [
            {
                iconId: 1,
                iconVector: <Microsoftazure />,
                iconDesc: "Microsoft Azure",
            },
            {
                iconId: 2,
                iconVector: <Googlecloud />,
                iconDesc: "Google Cloud",
            },
            {
                iconId: 3,
                iconVector: <Docker />,
                iconDesc: "Docker",
            },
        ],
    },
    {
        id: 7,
        title: "Game Development",
        iconVector: <GameDev />,
        // iconVectorBig: <GameDevBig />,
        iconVectorBig: GameDevBig,
        class: "lightpink",
        rowColClass: "service_col service_col--reverse",

        serviceDesc: "We transform games from a unique concept to a playable reality through appealing designs, graphics and animation that can engage a lot of users.",
        toolsDesc: "Tools We Use",
        buttonText: "Leverage our developers now",
        toolsIcons: [
            {
                iconId: 1,
                iconVector: <FaUnity style={{ fill: "white" }} />,
                iconDesc: "Unity",
            },
            {
                iconId: 2,
                iconVector: <SiUnrealengine style={{ fill: "white" }} />,
                iconDesc: "Unreal Engine",
            },
            {
                iconId: 3,
                iconVector: <Blender />,
                iconDesc: "Blender",
            },
        ],
    },
];
